import mdu1 from "./assets/insight/meenakshi_temple.jpg"
import mdu2 from "./assets/insight/palamuthirsolai.jpg"
import mdu3 from "./assets/insight/azhagarkovil.jpg"
import mdu4 from "./assets/insight/Thiruparankundram-Murugan-Temple.jpg"
import mdu5 from "./assets/insight/tirumalainayakarmahal.jpg"
import mdu6 from "./assets/insight/madurai-vandiyur-mariamman-teppakulam-1024x640.jpg"
import mdu7 from "./assets/insight/gandhimusummadurai.jpg"

import the1 from "./assets/insight/periyar-national-park.jpg"
import the2 from "./assets/insight/periyar-lake-boating.jpg"
import the3 from "./assets/insight/Periyar-Tiger-Trail.jpg"
import the4 from "./assets/insight/bamboo-rafting2.jpg"
import the5 from "./assets/insight/ayurvedic-spices-plantation.jpg"
import the6 from "./assets/insight/kathakali-show.jpg"
import the7 from "./assets/insight/kadathanadan-kalari-centre.jpg"
import the8 from "./assets/insight/jeep-safari-2.jpg"

import mun1 from "./assets/insight/Munnar-Top-Station.jpg"
import mun2 from "./assets/insight/Lakkam-Waterfall-Munnar-4.jpg"
import mun3 from "./assets/insight/echo-point-munnar2.jpg"
import mun4 from "./assets/insight/tea-estates.jpg"
import mun5 from "./assets/insight/tea-museum.jpg"
import mun6 from "./assets/insight/Eravikulam-National-Park.jpg"
import mun7 from "./assets/insight/attukad-waterfall.jpg"
import mun8 from "./assets/insight/Blossom-Park.jpg"
import mun9 from "./assets/insight/mattupatty-dam.png"
import mun10 from "./assets/insight/kundala-dam-lake2.jpg"
import mun11 from "./assets/insight/pothumedu.jpg"
import mun12 from "./assets/insight/chinnar-wild-life.jpg"
import mun13 from "./assets/insight/Cheeyappara-Waterfalls_munnar.jpg"
import mun14 from "./assets/insight/mattupetty-indo-swiss-farm-munnar-kerala-india-1567244597.jpg"

import ram1 from "./assets/insight/temple.jpg"
import ram2 from "./assets/insight/dhanushkodi-beach.jpg"
import ram3 from "./assets/insight/dhanushkodi-temple.jpg"
import ram4 from "./assets/insight/Kothandaramaswamy-Temple.jpg"
import ram5 from "./assets/insight/jatayu-tirtham.jpg"
import ram6 from "./assets/insight/agnithirtham.jpg"
import ram7 from "./assets/insight/ramar-patham.jpg"
import ram8 from "./assets/insight/rama-tirtham-gandamadana.jpg"
import ram9 from "./assets/insight/lakshmana-tirtham.jpg"
import ram10 from "./assets/insight/five-faced-hanuman.jpg"
import ram11 from "./assets/insight/Ariyaman-Beach-Rameswaram.jpg"
import ram12 from "./assets/insight/kushi-beach.jpg"
import ram13 from "./assets/insight/pamban-road-bridge.jpg"
import ram14 from "./assets/insight/apj-memorial.jpg"
import ram15 from "./assets/insight/apj-house.jpg"

import kod1 from "./assets/insight/kodai-lake-kodaikanal-768x576.jpg"
import kod2 from "./assets/insight/kodaikanalboating.jpg"
import kod3 from "./assets/insight/bryant-park-kodaikanal.jpg"
import kod4 from "./assets/insight/rosegarden.jpg"
import kod5 from "./assets/insight/berijam.jpeg"
import kod6 from "./assets/insight/Moir-Point-38.jpg"
import kod7 from "./assets/insight/BearShola.jpg"
import kod8 from "./assets/insight/upperlakeview.jpg"
import kod9 from "./assets/insight/kodaikanal-solar-observatory.jpg"
import kod10 from "./assets/insight/pineforest.jpg"
import kod11 from "./assets/insight/pillarRock.jpg"
import kod12 from "./assets/insight/side-view-of-dolphins-nose.jpg"
import kod13 from "./assets/insight/greenvalleyview.jpg"
import kod14 from "./assets/insight/Kodaikanal-Kodai_Lake-Coakers_Walk.jpg"
import kod15 from "./assets/insight/Kurinji-Andavar-Murugan-Temple.jpg"

import thi1 from "./assets/insight/Padmanabhaswamy_Temple.jpg"
import thi2 from "./assets/insight/kovalam-beach.jpg"
import thi3 from "./assets/insight/poovar-beach.jpeg"
import thi4 from "./assets/insight/Poovar-Island-Back-Water-Boating-point.jpg"
import thi5 from "./assets/insight/Vellayani_Lake.jpg"
import thi6 from "./assets/insight/ayurveda-medical-college-thiruvananthapuram-gpo-thiruvananthapuram.jpg"

import kan1 from "./assets/insight/Kanyakumari-temple.jpg"
import kan2 from "./assets/insight/kanyakumari-beach.jpg"
import kan3 from "./assets/insight/kanyakumari-boating-point.jpg"
import kan4 from "./assets/insight/Kanyakumari_Thiruvalluvar_Statue.jpeg"
import kan5 from "./assets/insight/vivekanandha-RockMemorial.jpg"
import kan6 from "./assets/insight/sun-rise.jpg"
import kan7 from "./assets/insight/vatta-kottai-fort2.jpg"

import nor1 from "./assets/packages/Rajasthan-450x300.jpeg"
import nor2 from "./assets/packages/goa.jpg"
import nor3 from "./assets/packages/gujarat1.jpg"
import nor4 from "./assets/packages/gujarat-package-9days.jpg"
import nor5 from "./assets/packages/uttarakhand.jpg"
import nor6 from "./assets/packages/kasi.jpg"
import nor7 from "./assets/packages/assam-meghalaya-tour.jpg"
import nor8 from "./assets/packages/kashmir.jpg"
import nor9 from "./assets/packages/leh-ladakh-tour-packages.jpg"
import madhya from "./assets/touristPlaces/madhya.jpg"
import punjab from "./assets/touristPlaces/punjab.jpg"



import thailand from "./assets/images/thailand.jpg"
import singapore from "./assets/images/singapore.jpg"
import veitnam from "./assets/images/veitnam.jpg"
import saudi from "./assets/images/saudi.jpg"
import dubai from "./assets/images/dubai.jpg"
import maldives from "./assets/images/maldives.jpg"
import andamon from "./assets/images/andaman.jpg"
import ooty from "./assets/touristPlaces/ooty.jpg"
import hima from "./assets/touristPlaces/himachal.jpg"
import sikkim from "./assets/touristPlaces/sikkim.jpg"
import mar from "./assets/packages/Mauritius.jpg"
import sri from "./assets/packages/srilanka.jpg"

import rameswaram from "./assets/pageBanners/rameswaram.jpg"
import kodai from "./assets/touristPlaces/kodaikanal.jpg"
import thekkady1 from "./assets/pageBanners/thekkady.jpg"

import temp from "./assets/Dharshan/templelogo.png";
import shir from "./assets/Dharshan/Shiridi.jpg";
import yamu from "./assets/Dharshan/Yamu.jpg";
import raman from "./assets/Dharshan/ram-mandir-ayodhya-image.jpg"
import dw from "./assets/Dharshan/dwarka.jpg";
import kasi from "./assets/Dharshan/kasi.jpg";
import jothi from "./assets/Dharshan/jothi.jpg";
import img1 from "./assets/Dharshan/Madurai.jpg";
import img2 from "./assets/Dharshan/img2.jpg";
import img3 from "./assets/Dharshan/img3.jpg";
import img4 from "./assets/Dharshan/img4.jpg"  ;
import img5 from "./assets/Dharshan/img5.jpg"   
import img6 from "./assets/Dharshan/img6.jpg"  
import img7 from "./assets/Dharshan/img7.jpg" 
import img8 from "./assets/Dharshan/img8.jpg"  


import south_madurai from "./assets/south_india/madurai.e9492631caa459b6a4eb.jpg"
import south_rameswaram from "./assets/south_india/rameshwaram.65892d6bd494be0d1cf9.jpg"
import south_kanniyakumari from "./assets/south_india/kanniyakumari.8d9900dd8241fb4d3db4.jpg"
import south_munnar from "./assets/south_india/munnar.b73f73998598a8425c84.jpg"
import south_trichy from "./assets/south_india/trichii.9c42f95d41db4456c171.jpg"
import south_uthiragosaimangai from "./assets/south_india/Uthirakosamangai.0860d6c97e94a1af63ba.jpg"
import south_thanjavur from "./assets/south_india/tanjavur.942f04c77791c601123a.jpg"
import south_ooty from "./assets/south_india/ooty.83c17affdc0dd3055b53.jpg"
import south_kovai from "./assets/south_india/kovai.d3dea7a9f4736e805b46.jpg"
import south_bangalore from "./assets/south_india/bangalore.76bae77f50c345105a42.jpg"
import south_thiruvanathapuram from "./assets/south_india/thiruvananthapuram.ba90ea5a4c68e3d49708.jpg"
import south_thrupath from "./assets/south_india/thirupathi.f17213cb377bd8ba0814.jpg"
import south_kodaikanal from "./assets/south_india/kodai.3fb6e7766f59a303ab2e.jpg"
import south_calcut from "./assets/south_india/calicut.7975e7bc4b996342d8b1.jpg"






const madurai = [{ img: mdu1, places: "Madurai Meenakshi Amman Temple" },
{ img: mdu2, places: "Pazhamudhir Solai" },
{ img: mdu3, places: "Alagarkoil Temple and Shrine" },
{ img: mdu4, places: "Tirupparankunram" },
{ img: mdu5, places: "Thirumalai Nayakar Mahal" },
{ img: mdu6, places: "Vandiyur Mariamman Teppakulam" },
{ img: mdu7, places: "Gandhi Museum" }]

const thekkady = [{ img: the1, places: "Periyar National Park" },
{ img: the2, places: "Periyar Lake Boating" },
{ img: the3, places: "Periyar Tiger Trail" },
{ img: the4, places: "Bamboo Rafting" },
{ img: the5, places: "Ayurvedic & Spices Plantation" },
{ img: the6, places: "Traditional dance Kathakali Show" },
{ img: the7, places: "Martial arts, Kalaripayyattu Show" },
{ img: the8, places: "Thekkady Forest Jeep Safari" }]

const munnar = [{ img: mun1, places: "Munnar Top Station" },
{ img: mun2, places: "Lakkam Waterfalls" },
{ img: mun3, places: "Echo Point" },
{ img: mun4, places: "Tea Estates" },
{ img: mun5, places: "Tea Museum" },
{ img: mun6, places: "Eravikulam National Park" },
{ img: mun7, places: "Attukad Waterfalls" },
{ img: mun8, places: "Blossom Park" },
{ img: mun9, places: "Mattupetty Dam" },
{ img: mun10, places: "Kundala Lake" },
{ img: mun11, places: "Pothamedu View Point" },
{ img: mun12, places: "Chinnar Wildlife Sanctuary" },
{ img: mun13, places: "Cheeyapara Waterfalls" },
{ img: mun14, places: "Mattupetty Dairy Farm" }
]


const rameshwaram = [{ img: ram1, places: "Rameshwaram Temple" },
{ img: ram2, places: "Dhanushkodi Beach" },
{ img: ram3, places: "Dhanushkodi Temple " },
{ img: ram4, places: "Kothada Ramar Temple" },
{ img: ram5, places: "Jatayu Tirtham" },
{ img: ram6, places: "Agnitheertham" },
{ img: ram7, places: "Ramar Patham" },
{ img: ram8, places: "Ram Tirtham" },
{ img: ram9, places: "Lakshmana Tirtham" },
{ img: ram10, places: "The Five-faced Hanuman Temple" },
{ img: ram11, places: "Ariyaman Beach " },
{ img: ram12, places: "Kusi Beach" },
{ img: ram13, places: "Annai Indira Gandhi Road Bridge (Pamban Bridge)" },
{ img: ram14, places: "Abdul Kalam National Memorial " },
{ img: ram15, places: "Abdul Kalam House" }
]

const kodaikanal = [{ img: kod1, places: "Kodai Lake" },
{ img: kod2, places: "Kodai Lake - Boating" },
{ img: kod3, places: "Bryant Park" },
{ img: kod4, places: "Rose Garden" },
{ img: kod5, places: "Berijam Lake" },
{ img: kod6, places: "Mirror Point" },
{ img: kod7, places: "Bear Shola Falls" },
{ img: kod8, places: "Upper Lake View" },
{ img: kod9, places: "Kodaikanal Solar Observatory" },
{ img: kod10, places: "Pine Forest" },
{ img: kod11, places: "Pillar Rocks" },
{ img: kod12, places: "Dolphin's Nose" },
{ img: kod13, places: "Green Valley View (Suicide Point)" },
{ img: kod14, places: "Coakers Walk" },
{ img: kod15, places: "Kurinji Andavar Temple" }
]

const thiruvanandhapuram = [{ img: thi1, places: "Padmanabhaswamy Temple" },
{ img: thi2, places: "Kovalam Beach" },
{ img: thi3, places: "Poovar Beach" },
{ img: thi4, places: "Poovar Island Back Water Boating point" },
{ img: thi5, places: "Vellayani Lake" },
{ img: thi6, places: "Ayurveda center" }]

const kanyakumari = [{ img: kan1, places: "Kumari Amman Temple" },
{ img: kan2, places: "Kanyakumari Beach" },
{ img: kan3, places: "Kanyakumari Boating point" },
{ img: kan4, places: "Thiruvalluvar Statue" },
{ img: kan5, places: "Vivekananda Rock Memorial" },
{ img: kan6, places: "Sun Rise / Sunset Point" },
{ img: kan7, places: "Vattakottai Fort" }]

const north_india = [
{ img: nor1, city: "Rajasthan", days: 8, nights: 7, places: "Jaipur - Bikaner - Jaisalmer - Jodhpur - Udaipur",Link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : Rajasthan%0ADays :7 Nights /  8 Days" },
{ img: nor2, city: "Goa", days: 6, nights: 5, places: "Panjim - Bardez - Calangute - Canacona.",link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : Goa%0ADays :5 Nights /  6 Days" },
{ img: nor3, city: "Gujarat", days: 5, nights: 4, places: "Ahmedabad - Vadodara - Ahmedabad",link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : Gujarat%0ADays :4 Nights /  5 Days" },
{ img: nor4, city: "Gujarat", days: 10, nights: 9, places: "Ahmedabad - Dwarka - Somnath -  Diu - Sasan Gir - Bhavnagar - Vadodara - Ahmedabad",link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : Gujarat%0ADays :9 Nights /  10 Days" },
{ img: nor5, city: "Uttarakhand", days: 10, nights: 9, places: "Nainital - Binsar - Auli - Chopta - Rishikesh",link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : Uttarakhand%0ADays :9 Nights /  10 Days" },
{ img: nor6, city: "UTTAR PRADESH", days: 8, nights: 7, places: "Jaipur - Bikaner - Jaisalmer - Jodhpur - Udaipur.",link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : UTTAR%20PRADESH%0ADays :7 Nights /  8 Days" },
{ img: nor6, city: "UTTAR PRADESH VARANASI", days: 7, nights: 6, places: "Varanasi - Ayodhya - Praygaraj - Lucknow - Chitrakoot." ,link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : UTTAR%20PRADESH%20VARANASI%0ADays :6 Nights /  7 Days"},
{ img: nor6, city: "UTTAR PRADESH Bodhgaya", days: 6, nights: 5, places: "Bodhgaya- Gaya - Patna - Rajgir - Nalanda - deoghar.",link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : UTTAR%20PRADESH%20Bodhgaya%0ADays :5 Nights /  6 Days" },
{ img: nor6, city: "UTTAR PRADESH Mathura", days: 6, nights: 5, places: "Mathura - Vrindavan - Agra - Naimishanya.",link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : UTTAR%20PRADESH%20Mathura%0ADays :5 Nights /  6 Days" },
{ img: nor7, city: "ASSAM & MEGHALAYA", days: 6, nights: 5,link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : ASSAM%20%26%20MEGHALAYA%0ADays :5 Nights /  6 Days" },
{ img: nor8, city: "JAMMU & KASHMIR", days: 5, nights: 4, places: "Gulmarg - Sonamarg - Pahalgam - Srinagar",link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : JAMMU%20%26%20KASHMIR%0ADays :4 Nights /  5 Days" },
{ img: nor9, city: " LEH LADAKH", days: 5, nights: 4, places: "Leh - Nubra Valley -Pangong Lake",link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : %20LEH%20LADAKH%0ADays :4 Nights /  5 Days"},
{ img: madhya, city: " Madhya Pradesh", days: 7, nights: 6, places: "Ujjain - Omkareshwar - Maheshwar - Pachmarhi - Kanha -Khajuraho",link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : %20Madhya%20Pradesh%0ADays :6 Nights /  7 Days" },
{ img: hima, city: "Himachal", days: 9, nights: 8, places: "Delhi - Shimla - Kufri - Manali - Gulaba - Dharamashala - Khajjari DulaHousie.",link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : Himachal%0ADays :8 Nights /  9 Days" },
{ img: punjab, city: " PUNJAB & HARYANA", days: 6, nights: 5 ,link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : %20PUNJAB%20%26%20HARYANA%0ADays :5 Nights /  6 Days"}
]
const south_india = [
    { img: south_madurai, city: "MADURAI - RAMESWARAM – KANYAKUMARI", days: 3, nights: 2, link: "https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : MADURAI%20-%20RAMESWARAM%20%E2%80%93%20KANYAKUMARI%0ADays :2 Nights /  3 Days" },
    { img: south_rameswaram, city: "MADURAI - RAMESWARAM – KANYAKUMARI --THIRUVANATHAPURAM", days: 4, nights: 3, link: "https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : MADURAI%20-%20RAMESWARAM%20%E2%80%93%20KANYAKUMARI%20--THIRUVANATHAPURAM%20%0ADays :3 Nights /  4 Days" },
    { img: south_kanniyakumari, city: "MADURAI - KANAYAKUMARI -THIRUVANATHAPURAM - VARKALA - AELLPPEY – COCHIN", days: 6, nights: 5, link: "https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : MADURAI%20%E2%80%93%20KANAYAKUMARI%20-THIRUVANATHAPURAM%20-%20VARKALA%20-%20AELLPPEY%20%E2%80%93%20COCHIN%20%0ADays :5 Nights /  6 Days" },
    { img: south_munnar, city: "MADURAI - MUNNAR - THEKKADY - VAGAMON - AELLPPEY - VARKALA – COCHIN", days: 6, nights: 7, link: "https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : MADURAI%20-%20MUNNAR%20-%20THEKKADY%20-%20VAGAMON%20-%20AELLPPEY%20-%20VARKALA%20%E2%80%93%20COCHIN%20%0ADays :7 Nights /  6 Days" },
    { img: rameswaram, city: "MADURAI - THEKKADY - MUNNAR - AELLPPEY – COCHIN", days: 6, nights: 5, link: "https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : MADURAI%20-%20THEKKADY%20-%20MUNNAR%20-%20AELLPPEY%20%E2%80%93%20COCHIN%0ADays :5 Nights /  6 Days" },
    { img: south_trichy, city: "MADURAI - TRICHY - THANJAVUR - KUMBAKONAM - CHIDHABARAM - THIRUVANAMALAI - KANCHIPURAM - CHENNAI - THIRUPATHI", days: 6, nights: 5, link: "https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : MADURAI%20%E2%80%93%20TRICHY%20%E2%80%93THIRUVANAMALAI%20-%20KANCHIPURAM%20-%20KALAKASTHI%20%E2%80%93%20THIRUPATHI%0ADays :5 Nights /  6 Days" },
    { img: south_uthiragosaimangai, city: "MADURAI - UTHIRAGOSAIMANGAI - RAMESWARAM - DEVIPATTINAM - KARAIKUDI - TANJAVUR- TRICHY", days: 6, nights: 5, link: "https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : MADURAI%20-%20UTHIRAGOSAIMANGAI%20-%20RAMESWARAM%20-%20DEVIPATTINAM%20%20-%20KARAIKUDI%20-%20TANJAVUR-%20TRICHY%0ADays :5 Nights /  6 Days" },
    { img: south_thanjavur, city: "MADURAI - TRICHY - THANJAVUR - KUMBAKONAM - CHIDHABARAM - THIRUVANAMALAI - KANCHIPURAM - CHENNAI - THIRUPATHI", days: 10, nights: 9, link: "https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : MADURAI%20-%20TRICHY%20-%20THANJAVUR%20-%20KUMBAKONAM%20-%20CHIDHABARAM%20-%20THIRUVANAMALAI%20-%20KANCHIPURAM%20-%20CHENNAI%20-%20THIRUPATHI%20%0ADays :9 Nights /  10 Days" },
    { img: south_ooty, city: "Coimbatore – ooty – kodaikanal – munnar – Cochin", days: 8, nights: 7, link: "https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : Coimbatore%20%E2%80%93%20ooty%20%E2%80%93%20kodaikanal%20%E2%80%93%20munnar%20%E2%80%93%20Cochin%20%0ADays :7 Nights /  8 Days" },
    { img: south_kovai, city: "Coimbatore - Kodaikanal - Madurai - Rameswaram - Kanyakumari - Thiruvanthapuram", days: 8, nights: 7, link: "https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : Coimbatore%20%E2%80%93%20ooty%20%E2%80%93%20kodaikanal%20%E2%80%93%20munnar%20%E2%80%93%20Cochin%20%0ADays :7 Nights /  8 Days" },
    { img: south_bangalore, city: "Bangaluru –Mysore - Coorg – Nagarhole -Bandipur - Chikmagalur", days: 7, nights: 6, link: "https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : Bangaluru%20%E2%80%93Mysore%20-%20Coorg%20%E2%80%93%20Nagarhole%20-Bandipur%20-%20Chikmagalur%0ADays :6 Nights /  7 Days" },
    { img: south_thiruvanathapuram, city: "THIRUVANTHAPURAM – KANYAKUMARI – RAMESWARAM – MADURAI", days: 5, nights: 4, link: "https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : THIRUVANTHAPURAM%20%E2%80%93%20KANYAKUMARI%20%E2%80%93%20RAMESWARAM%20%E2%80%93%20MADURAI%20%0ADays :4 Nights /  5 Days" },
    { img: south_thrupath, city: "TRIUPATHI - CHENNAI – MAHABALIPURAM – KANCHIPURAM - CHIDABARAM – KUMBAKONAM – TANJAVUR – TRICHY – RAMESWARAM MADURAI", days: 10, nights: 9, link: "https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : TRIUPATHI%20-%20CHENNAI%20%E2%80%93%20MAHABALIPURAM%20%E2%80%93%20KANCHIPURAM%20-%20CHIDHABARAM%20%E2%80%93%20KUMBAKONAM%20%E2%80%93%20TANJAVUR%20%E2%80%93%20TRICHY%20%E2%80%93%20RAMESWARAM%20MADURAI%0ADays :9 Nights /  10 Days" },
    { img: south_kodaikanal, city: "MADURAI – KODAIKANAL –THEKKADY –VAGAMON - MUNNAR – COCHIN", days: 7, nights: 6, link: "https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : MADURAI%20%E2%80%93%20KODAIKANAL%20%E2%80%93THEKKADY%20%E2%80%93VAGAMON%20-%20MUNNAR%20%E2%80%93%20COCHIN%20%0ADays :6 Nights /  7 Days" },
    { img: south_calcut, city: "CAILCUT – ISHA YOGA – OOTY – KODAIKANL – MADURAI", days: 7, nights: 6, link: "https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : CAILCUT%20%E2%80%93%20ISHA%20YOGA%20%E2%80%93%20OOTY%20%E2%80%93%20KODAIKANL%20%E2%80%93%20MADURAI%20%0ADays :6 Nights /  7 Days" },
  ]
  

  
  

const international = [{ img: thailand, place: "thailand" },
{ img: singapore, place: "singapore" },
{ img: veitnam, place: "veitnam" },
{ img: saudi, place: "saudi " },
{ img: dubai, place: "dubai" },
{ img: maldives, place: "maldives" },
{ img: andamon, place: "andamon" },
{img:sri,place :"sri Lanka" },
{img:mar,place :"Mauritius" },]

const honeymoon = [{ img: sikkim, place: "sikkim" },
{ img: hima, place: "Himachal" },
{ img: veitnam, place: "Shimla" },
{ img: kod8, place: "Kodaikanal " },
{ img: the2, place: "Tekkady" },
{ img: ooty, place: "ooty" },
{ img: mun4, place: "Munnar" },
{ img: nor8, place: "Kashmir" },
{img:nor2,place:"Goa"}
]

const dharshan = [{
    img: img1, days: 5, nights: 4, link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : Madurai Meenakshi Amman temple - Rameswaram Ramanathaswamy temple- kombakonam Kasi Viswanathar temple - Thanjai Brihadishvara Temple - Trichy Srirangam Ranganatha Swamy Temple.%0ADays :4 Nights /  5 Days",places: `Madurai Meenakshi Amman temple - Rameswaram Ramanathaswamy temple

- kombakonam Kasi Viswanathar temple - Thanjai Brihadishvara Temple -
Trichy Srirangam Ranganatha Swamy Temple.`},
{
    img: img2, days: 5, nights: 4, link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : Madurai Meenakshi temple - Srivilliputtur Arulmigu Andal SamethaRangamannar Temple - Thirunelveli Arulmigu Nellaiappar Temple - The Aazhimala Shiva Temple Thiruvananthapuram - Sree PadmanabhaswamyTemple Thiruvananthapuram.%0ADays :4 Nights /  5 Days",places: `Madurai Meenakshi temple - Srivilliputtur Arulmigu Andal Sametha
Rangamannar Temple - Thirunelveli Arulmigu Nellaiappar Temple - The
Aazhimala Shiva Temple Thiruvananthapuram - Sree Padmanabhaswamy
Temple Thiruvananthapuram.`},
{
    img: img3, days: 6, nights: 5,link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : Madurai Meenakshi Amman temple -Trichy Srirangam Ranganatha SwamyTemple - Kanchipuram Kailasanathar Temple- Thiruvanamalai Arunachaleswarar Temple -Isha Yoga Temple Coimbatore.%0ADays :5 Nights /  6 Days", places: `Madurai Meenakshi Amman temple -Trichy Srirangam Ranganatha Swamy
Temple - Kanchipuram Kailasanathar Temple
- Thiruvanamalai Arunachaleswarar Temple -
Isha Yoga Temple Coimbatore.`},
{
    img: img4, days: 7, nights: 6,link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : Madurai Meenakshi Amman temple -Trichy Srirangam Ranganatha SwamyTemple - Thiruvanamalai Arunachaleswarar Temple - Kanchipuram Kailasanathar Temple - Mukundanayananar temple Mahabalipuram -Thriupathi Ellumalaiyan Temple.%0ADays :6 Nights /  7 Days", places: `Madurai Meenakshi Amman temple -Trichy Srirangam Ranganatha Swamy
Temple
- Thiruvanamalai Arunachaleswarar Temple -
Kanchipuram Kailasanathar Temple - Mukundanayananar temple Mahabalipuram -
Thriupathi Ellumalaiyan Temple.`},

{
    img: img5, days: 5, nights: 4,link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : Meenakshi Temple Madurai - Chottanikkara Bagavathi Amman temple - Guruvayur Guruvayurappan Temple - Masani Amman Temple Pollachi - Palani Murugan temple - Covai Essha Temple.%0ADays :4 Nights /  5 Days", places: `Meenakshi Temple Madurai - Chottanikkara Bagavathi Amman temple - Guruvayur Guruvayurappan Temple - Masani Amman Temple Pollachi - Palani
    Murugan temple - Covai Essha Temple.`},

    
{
    img: img6, days: 10, nights: 9,link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : Madurai Meenakshi Amman temple - Rameswaram Ramanathaswamy temple - Trichy Srirangam Ranganatha Swamy Temple -Thanjai Brihadishvara Temple - kombakonam Kasi Viswanathar temple - Chidabaram Thallai Nataraja temple - Thiruvanamalai Arunachaleswarar Temple - Kanchipuram Kailasanathar Temple - Vadapalani Murugan Temple Chennai.%0ADays :9 Nights /  10 Days", places: `Madurai Meenakshi Amman temple - Rameswaram Ramanathaswamy temple
    -
    Trichy Srirangam Ranganatha Swamy Temple -Thanjai Brihadishvara Temple
    - kombakonam Kasi Viswanathar temple
    - Chidabaram Thallai Nataraja temple - Thiruvanamalai Arunachaleswarar
    Temple -
    Kanchipuram Kailasanathar Temple - Vadapalani Murugan Temple Chennai.`},

        
{
    img: img7, days: 7, nights: 6,link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : Lord Murugan ( Kanthaswamy) - 06 Houses Thiruparankundram Murugan temple Madurai - Tiruchendur Murugan temple - Palani (Pazhani) Murugan Temple - Swamimalai Murugan Temple - Tiruthani Murugan Temple - Pazhamudircholai Murugan Temple Madurai.%0ADays :6 Nights /  7 Days", places: `Lord Murugan ( Kanthaswamy) - 06 Houses
    Thiruparankundram Murugan temple Madurai - Tiruchendur Murugan temple -
    Palani (Pazhani) Murugan Temple -
    Swamimalai Murugan Temple -
    Tiruthani Murugan Temple
    - Pazhamudircholai Murugan Temple Madurai.`},

    {
        img: img8, days: 6, nights: 5,link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : Madurai Meenakshi temple-Rameshwaram Ramanatha swamy temple - Arulmigu Tiruvengadamudaiyan Ariyakudi-Kumbakonam Navagraha temples -Thingaloor - Shri Kailasanathar Temple [Chandran Temple]-Brihadisvara Temole-Alangudi - Shri Apatsahayesvarar Guru Bhagavan Temple [Jupiter Temple]-Thirunageswaram - Shri Naganatha Swamy Temple [Rahu]- Thirumangalakudi - Shri Siva Suriyanar Temple [Sun Temple]-Kanchanur -Shri Agniswarar Shukran Temple [Venus Temple]-Thirunallar - Shri Saniswara Temple Saturn Temple-Kezhaperumpallam - Shri Naganathaswamy Temple [Kethu]-Thiruvengadu - Sri Swetharanveswarar Bhudan Temple Mercury Temple -Vaitheeswaram - Shri Vaitheeswaran Temple [Mars Temple]- Chidambaram Temple-Perumukkal Shiva Temple-Sri Kanchi Kamakshi Amman Temple-Sri Pallikondeswara Saamy Temple.%0ADays :5 Nights /  6 Days", places: `Madurai Meenakshi temple-Rameshwaram Ramanatha swamy tempe- Arulmigu Tiruvengadamudaiyan Ariyakudi-Kumbakonam Navagraha temples -
        Thingaloor - Shri Kailasanathar Temple [Chandran Temple]-Brihadisvara
        Temole-Alangudi - Shri Apatsahayesvarar Guru Bhagavan Temple [Jupiter
        Temple]-Thirunageswaram - Shri Naganatha Swamy Temple [Rahu]-
        Thirumangalakudi - Shri Siva Suriyanar Temple [Sun Temple]-Kanchanur -
        Shri Agniswarar Shukran Temple [Venus Temple]-Thirunallar - Shri Saniswara
        Temple Saturn Temple-Kezhaperumpallam - Shri Naganathaswamy Temple
        [Kethu]-Thiruvengadu - Sri Swetharanveswarar Bhudan Temple Mercury
        Temple -Vaitheeswaram - Shri Vaitheeswaran Temple [Mars Temple]-
        Chidambaram Temple-Perumukkal Shiva Temple-Sri Kanchi Kamakshi
        Amman Temple-Sri Pallikondeswara Saamy Temple.`},

]


const dharshan1 = [{
    img: shir, packageName: "Shirdi Dharshan Package", days: 5, nights: 4, places: `Sai Baba temple Shirdi - Jyotirlinga temple Grishneshwer - Kailashanatha
    temple Ellora - Shaneeshwara temple Shain Shingropur.`,link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : Sai Baba temple Shirdi - Jyotirlinga temple Grishneshwer - Kailashanatha temple Ellora - Shaneeshwara temple Shain Shingropur.%0ADays :4 Nights /  5 Days"},
{
    img: yamu, packageName: "Uttrakhand - Haridwar Dharshan package.", days: 8, nights: 7, places: `Yamunotri temple - Gangotri temple - kedarnath tempe - Badrinath temple
   - Neeleshwar temple.`,link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : Yamunotri temple - Gangotri temple - kedarnath tempe - Badrinath temple - Neeleshwar temple.%0ADays :7 Nights /  8 Days"},
{
    img: raman, packageName: "Uttar Pardesh Dharshan package", days: 5, nights: 4, places: `Shri Naimishnath Vishnu Temple
    - Ayoudha Ram temple
    - kasi viswanathar temple
    - kasi kala Bhairawar temple.`,link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : Shri Naimishnath Vishnu Temple - Ayoudha Ram temple - kasi viswanathar temple - kasi kala Bhairawar temple.%0ADays :4 Nights /  5 Days"},
{
    img: dw, packageName: "Gujarat Dharshan Package", days: 7, nights: 6, places: `Duwaraka temple
    - somnath temple
    - Dakor Duwaraka temple
    - Dwarkadhish temple kankroil
    - Nishkalank Mahadev Temple`,link:"https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : Duwaraka temple - somnath temple - Dakor Duwaraka temple - Dwarkadhish temple kankroil - Nishkalank Mahadev Temple%0ADays :6 Nights /  7 Days"},

{
    img: kasi, packageName: "Kasi Dharshan Package", days: 5, nights: 4, places: `Kashi Vishwanath Temple
    - Varanasi
    - Sirsha Buddha Temple
    - Gaya
    - Mahabodhi Temple Bodhi Gaya.`,
    link:`https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : Kashi Vishwanath Temple
    - Varanasi
    - Sirsha Buddha Temple
    - Gaya
    - Mahabodhi Temple Bodhi Gaya.%0ADays :4 Nights /  5 Days`},

    
{
    img: jothi, packageName: "Kedarnath Dharshan Package", days: 6, nights: 5, places: `Jyotirlinga Kedarnath Temple
   - Chandi Devi Temple Haridwar
   - Neelkanth Mahadev Temple
   - Rishikesh
   - Badarinarayana Temple Badrinath.`,link:`https://wa.me/+916380562390?text=Whatsapp Enquiry%0A*******************%0APlaces : Jyotirlinga Kedarnath Temple
   - Chandi Devi Temple Haridwar
   - Neelkanth Mahadev Temple
   - Rishikesh
   - Badarinarayana Temple Badrinath.%0ADays :5 Nights /  6 Days`
},
]
export { madurai, thekkady, munnar, rameshwaram, kodaikanal, thiruvanandhapuram, kanyakumari, north_india, international, honeymoon, south_india, dharshan,dharshan1}