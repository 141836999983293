
import Footer from './Footer'
import Header from './Header'
import React from 'react'
import TourPackage from './TourPackage'
import DomesticTourPackage from './DomesticTourPackage'
import InternationalTour from './InternationalTour'
import Dharshan from './Dharshan'

const Packages = ({tour,pack,pack1}) => {
  return (
    <div>
        <Header/>
        {tour=="DOMESTIC TOUR PACKAGE"?(<DomesticTourPackage tour={tour}/>):tour=="INTERNATIONAL TOUR PACKAGE" || tour == "HONEYMOON TOUR PACKAGE" ?(<InternationalTour tour={tour} pack={pack}/>)
        :tour == "DAHRASHAN TOUR PACKAGE"?(<Dharshan tour={tour} pack={pack} pack1={pack1}/>):(<TourPackage tour={tour} pack={pack}/>)
        }
        <Footer/>
    </div>
  )
}

export default Packages