import React from 'react'
import Modal from 'react-modal'
import { Divider } from 'antd';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"

const Popup = ({ isOpen, onRequestClose, fieldChange }) => {

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '90%', // Adjust the width as needed
            maxWidth: '600px', // Set a max width if desired
            padding: '2rem',

        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the color and transparency
            zIndex: 9999, // Adjust the z-index value as needed
        },
    };
    const {
        register,
        handleSubmit,
        formState: { errors },
        trigger,
    } = useForm();

    const [whatsaappMsg, setWhatsaappMsg] = useState();

    const toasOptions = {
        position: "top-right",
        autoClose: 5000,
        pauseOnHover: true,
        draggable: true,
    }

    const onSubmit = async (event) => {

        trigger()
        event.preventDefault();

        await handleSubmit(async (data) => {
            const formData = new FormData(event.target);
            const formValues = Object.fromEntries(formData.entries());

            const handleValidation = () => {

                const { name, Phone_no, car_type, packvalues, depature_date, depature_time, travel_details } = formValues;
                if (name === "") {
                    toast.error("Name is required", toasOptions)
                    return false;
                }
                else if (Phone_no === "") {
                    toast.error("Phone  Number is required", toasOptions)
                    return false;
                }
                else if (car_type === "select car type") {
                    toast.error("Please Select Car type", toasOptions)
                    return false;
                }
                else if (packvalues === "select travel distance") {
                    toast.error("Please Select Travel distance", toasOptions)
                    return false;
                }
                else if (depature_date === "") {
                    toast.error("Please Select Departure Date", toasOptions)
                    return false;
                }
                else if (depature_time === "") {
                    toast.error("Please Select Departure Time", toasOptions)
                    return false;

                }
                else if (travel_details === "") {
                    toast.error("Travel details is required", toasOptions)
                    return false;
                }
                return true;
            }

            if (handleValidation()) {

                const pack = fieldChange == "hp" ? "Hourly Package / Travel distance : " + formValues.packvalues + " km "
                    : fieldChange == "dp" ? "Day Package / No of Days : " + formValues.packvalues + " Days "
                        : " Day rent Package / No of Days : " + formValues.packvalues + " Days "

                const pack_type=fieldChange=="hp"?"Hrs :":"Km :"; 

                const Allowed_Km =fieldChange=="dp"?formValues.car_type=="SEDAN" || formValues.car_type=="MINI" ? (formValues.packvalues*250)
                :formValues.car_type=="SUV"|| formValues.car_type=="INNOVA"? (formValues.packvalues*300)
                :(formValues.packvalues*350):fieldChange=="hp"?((formValues.packvalues/20)+1):0;

                const price = fieldChange == "hp"?  (formValues.car_type=="MINI") ?(((formValues.packvalues/20)+1)*280):
                                                    (formValues.car_type=="SEDAN") ?(((formValues.packvalues/20)+1)*300):
                                                    (formValues.car_type=="SUV") ?(((formValues.packvalues/20)+1)*400):
                                                    (((formValues.packvalues/20)+1)*450):
                            fieldChange == "dp" ?   (formValues.car_type=="MINI") ?(formValues.packvalues*3050):
                                                    (formValues.car_type=="SEDAN") ?(formValues.packvalues*3400):
                                                    (formValues.car_type=="SUV") ?(formValues.packvalues*5300):
                                                     (formValues.car_type=="INNOVA") ?(formValues.packvalues*5600):
                                                     (formValues.packvalues*8750):
                           (formValues.car_type=="MINI")?1400:
                           (formValues.car_type=="SEDAN")?1500:
                           (formValues.car_type=="SUV")?2000:
                           (formValues.car_type=="INNOVA")?2300:2800

                                    
                                                 

             
                const whatsaappMsg = "Website Enquiry%0A******* *******%0AYour Booking Details:%0A%0AName : " + formValues.name + " ,%0A%0AMobile Number  : +91" + formValues.Phone_no + " ,%0A%0ACar Type  : " + formValues.car_type + "%0A%0APack :" + pack +"%0A%0AAllowed "+ pack_type + Allowed_Km + "%0A%0A Price : "+ price +"%0A%0ADeparture Date/Time: " + formValues.depature_date + " " + formValues.depature_time + "%0A%0A Travel Details :" + formValues.travel_details + ",%0A%0AToll, parking, permit extra %0A%0AFor any questions please contact +916380562390 %0A%0A"

                setWhatsaappMsg(whatsaappMsg)

                window.open("https://wa.me/+916380562390?text=" + whatsaappMsg);
                onRequestClose()
            }

        })(event);



    }



    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Example Modal"
            style={customStyles} >
            <ToastContainer />
            <h2 className='form-title'> Booking Details</h2>
            <Divider dashed style={{ borderColor: "black" }} />
            <form className='enquiry-form' onSubmit={onSubmit}>
                <div>
                    <label>Name</label>
                    <input type="text" name="name" placeholder='Enter name' />
                </div>
                <div>
                    <label>Mobile Number</label>
                    <input type="text" name="Phone_no" placeholder='Enter Mobile Number' />
                </div>
                {fieldChange == 'hp' ? (<div>
                    <label>Car Type</label>
                    <select name='car_type'>
                        <option>select car type</option>
                        <option>SEDAN</option>
                        <option>MINI</option>
                        <option>SUV</option>
                        <option>INNOVA</option>
                    </select>
                </div>) : fieldChange == 'dp' ? (<div>
                    <label>Car Type</label>
                    <select name='car_type'>
                        <option>select car type</option>
                        <option>MINI</option>
                        <option>SEDAN</option>
                        <option>SUV</option>
                        <option>INNOVA</option>
                        <option>TEMPO</option>
                    </select>
                </div>) : (<div>
                    <label>Car Type</label>
                    <select name='car_type'>
                        <option>select car type</option>
                        <option>SEDAN</option>
                        <option>MINI</option>
                        <option>SUV</option>
                        <option>INNOVA</option>
                        <option>TEMPO</option>
                    </select>
                </div>)}

                {fieldChange == 'hp' ?
                    (<div>
                        <label>Travel Distance</label>
                        <select name='packvalues'>
                            <option>select travel distance</option>
                            <option>10</option>
                            <option>20</option>
                            <option>40</option>
                            <option>60</option>
                            <option>80</option>
                            <option>100</option>
                            <option>120</option>
                            <option>140</option>
                            <option>160</option>
                            <option>180</option>
                            <option>200</option>
                            <option>220</option>
                        </select>
                    </div>) : (<div>
                        <label>Select No of Days</label>
                        <select name='packvalues'>
                            <option>select no of days</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                        </select>
                    </div>)}
                <div>
                    <label>
                        Depature Date
                    </label>
                    <input type="date" name='depature_date'></input>
                </div>
                <div>
                    <label>Depature Time</label>
                    <input type="time" name='depature_time' ></input>
                </div>

                <div>
                    <label>Travel Details</label>
                    <textarea type="text" name='travel_details' placeholder='Enter Travel Details' rows="3"></textarea>
                </div>

                <div className='btn-container'>
                    <input type="submit" value="SEND"></input>
                    <input type="button" value="close" onClick={onRequestClose}></input>
                </div>
            </form>

        </Modal>
    )
}

export default Popup