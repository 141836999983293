import React from 'react'
import { FaLocationDot } from "react-icons/fa6";

const TourPackageCard = ({ card_img, city, days, nights, place,link }) => {
  return (
    <div className='bg-white py-4 px-4 md:px-10 mr-0 sm:mr-2 md:mr-3 lg:mr-6 flex flex-col h-full'>
      {/* Image section */}
      <div className='pb-10 bg-red-300 ex-img flex-grow' style={{ backgroundImage: `url(${card_img})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      </div>

      {/* Content section */}
      <div className='route-card1 flex flex-col flex-grow'>
        <h5 className='title'>{city} Tour Package</h5>
        <h5>{nights} Nights / {days} Days</h5>
        {place && (
          <h5><span><FaLocationDot /></span> {place}</h5>
        )}
      </div>

      {/* WhatsApp Enquiry Button */}
      <a
        href={link}
        target="__blank"
        className="bg-[#fcb708] w-full flex text-center justify-center items-center font-semibold mt-auto text-black py-4 px-8 text-[16px] sm:text-[17px] md:text-[18px] uppercase hover:text-white hover:bg-black transition-all ease-linear"
      >
        WhatsApp Enquiry
      </a>
    </div>
  )
}

export default TourPackageCard;
