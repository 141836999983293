import React from 'react'
import { FaArrowRight } from "react-icons/fa";
const ChooseUs = ({icon,title,desc}) => {
  return (
    <div className='choose-card'>
        <div className='icon'>
            {icon}
        </div>
        <h3 className='title'>{title}</h3>
        <p className="desc">{desc}</p>
        <a href='tel:+916380562390'>
        <button className='call-btn'>call now <FaArrowRight/></button></a>
    </div>
  )
}

export default ChooseUs