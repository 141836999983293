import React from "react";
import { FaLocationDot } from "react-icons/fa6";
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaMapMarkerAlt, FaEnvelope, FaPhoneAlt, FaBuilding } from "react-icons/fa";
import phoneImage from '../assets/images/phone_img.png';
import whatsappImage from '../assets/images/whatsapp.png'
import { IoIosArrowRoundUp } from "react-icons/io";

const Footer = () => {
  const topCitiesPackages = [
    { name: "TOURS PACKAGE FROM MADURAI", url: "/madurai" },
    {
      name: "TOURS PACKAGE FROM RAMESWARAM",
      url: "/rameshwaram",
    },
    {
      name: "TOURS PACKAGE FROM KANYAKUMARI",
      url: "/kanayakumari",
    },
    {
      name: "TOURS PACKAGE FROM THEKKADY",
      url: "/thekkady",
    },
    {
      name: "TOURS PACKAGE FROM CHENNAI",
      url: "/chennai",
    },
    {
      name: "TOURS PACKAGE FROM COIMBATORE",
      url: "/coimbatore",
    },
    {
      name: "TOURS PACKAGE FROM BANGALORE",
      url: "/bangalore",
    },
    {
      name: "TOURS PACKAGE FROM THIRUVANATHAPURAM",
      url: "/thiruvanathapuram",
    },
    {
      name: "TOURS PACKAGE FROM KODAIKANAL",
      url: "/kodaikanal",
    },
    {
      name: "TOURS PACKAGE FROM MUNNAR",
      url: "/moonar",
    },
    {
      name: "TOURS PACKAGE FROM PONDICHERRY",
      url: "/pondicherry",
    },
    {
      name: "TOURS PACKAGE FROM COCHIN",
      url: "/cochin",
    },
  ];

  const southIndiaPackages = [
    {
      name: "MADURAI - RAMESWARAM – KANYAKUMARI TOUR PACKAGE",
      url: "/south-tour-package",
    },
    {
      name: "MADURAI - RAMESWARAM – KANYAKUMARI --THIRUVANATHAPURAM TOUR PACKAGE",
      url: "/south-tour-package",
    },
    {
      name: "MADURAI - MUNNAR - THEKKADY - VAGAMON - AELLPPEY - VARKALA – COCHIN TOUR PACKAGE",
      url: "/south-tour-package",
    },
    {
      name: "MADURAI – KANAYAKUMARI -THIRUVANATHAPURAM - VARKALA - AELLPPEY – COCHIN TOUR PACKAGE",
      url: "/south-tour-package",
    },
    {
      name: "MADURAI - UTHIRAGOSAIMANGAI - RAMESWARAM - DEVIPATTINAM - KARAIKUDI - TANJAVUR- TRICHY TOUR PACKAGE",
      url: "/south-tour-package",
    },
    {
      name: "MADURAI - TRICHY - THANJAVUR - KUMBAKONAM - CHIDHABARAM - THIRUVANAMALAI - KANCHIPURAM - CHENNAI - THIRUPATHI TOUR PACKAGE",
      url: "/south-tour-package",
    },
    {
      name: "COIMBATORE - KODAIKANAL - MADURAI - RAMESWARAM - KANYAKUMARI - THIRUVANATHAPURAM TOUR PACKAGE",
      url: "/south-tour-package",
    },
    {
      name: "COIMBATORE – OOTY – KODAIKANAL – MUNNAR – COCHIN TOUR PACKAGE",
      url: "/south-tour-package",
    },
    {
      name: "BANGALORE-MYSORE, COORG -NAGARHOLE, BANDIPUR - CHIKMAGALUR TOUR PACKAGE",
      url: "/south-tour-package",
    },
    {
      name: "THIRUVANTHAPURAM – KANYAKUMARI – RAMESWARAM – MADURAI TOUR PACKAGE",
      url: "/south-tour-package",
    },
    {
      name: "MADURAI – TRICHY - THIRUVANAMALAI – KANCHIPURAM - KALAKASTHI – THIRUPATHI TOUR PACKAGE",
      url: "/south-tour-package",
    },
    {
      name: "TRIUPATHI - CHENNAI – MAHABALIPURAM – KANCHIPURAM – CHIDABARAM – KUMBAKONAM – TANJAVUR – TRICHY – RAMESWARAM MADURAI - TOUR PACKAGE",
      url: "/south-tour-package",
    },
    {
      name: "MADURAI – KODAIKANAL –THEKKADY –VAGAMON - MUNNAR – COCHIN TOUR PACAKGE",
      url: "/south-tour-package",
    },
    {
      name: "CAILCUT – ISHA YOGA – OOTY – KODAIKANL – MADURAI TOURS PACKAGE",
      url: "/south-tour-package",
    },
  ];
  const internationalPackages = [
    "THAILAND TOUR PACKAGE",
    "TOURS PACKAGE FROM RAMESWARAM",
    "SINGAPORE TOUR PACKAGE",
    "VIETNAM TOUR PACKAGE",
    "SAUDI TOUR PACKAGE",
    "DUBAI TOUR PACKAGE",
    "MALDIVES TOUR PACKAGE",
    "ANDAMAN TOUR PACKAGE",
    "MALAYSIA TOUR PACKAGE",
    "LAKSHADWEEP TOUR PACKAGE",
    "BALI - INDONESIA TOUR PACKAGE",
    "SRI LANKA TOUR PACKAGE",
    "MAURITIUS TOUR PACKAGE",
  ];

  const northIndiaPackages = [
    "Jaipur - Bikaner - Jaisalmer - Jodhpur – Udaipur- RAJASTHAN TOUR PACKAGE",
    "Panjim - Bardez - Calangute – Canacona - GOA TOUR PACKAGE",
    "Ahmedabad - Vadodara - Ahmedabad-GUJARAT TOUR PACKAGE",
    "Ahmedabad - Dwarka - Somnath - Diu - Sasan Gir - Bhavnagar - Vadodara – Ahmedabad - GUJARAT TOUR PACKAGE",
    "Nainital - Binsar - Auli - Chopta -Rishikesh - UTTARAKHAND TOUR PACKAGE",
    "Jaipur - Bikaner - Jaisalmer - Jodhpur - Udaipur. UTTAR PRADESH TOUR PACKAGE",
    "Varanasi - Ayodhya - Praygaraj - Lucknow – Chitrakoot - UTTAR PRADESH TOUR PACKAGE",
    "Bodhgaya- Gaya - Patna - Rajgir - Nalanda - Deoghar UTTAR PRADESH TOUR PACKAGE",
    "Mathura - Vrindavan - Agra – Naimishanya- UTTAR PRADESH TOUR PACKAGE",
    "Gulmarg - Sonamarg - Pahalgam – Srinagar- JAMMU & KASHMIR TOUR PACKAGE",
    "Leh - Nubra Valley - Pangong Lake- LEH LADAKH TOUR PACKAGE",
    "Ujjain - Omkareshwar - Maheshwar - Pachmarhi - Kanha – Khajuraho- MADHYA PRADESH TOUR PACKAGE",
    "Delhi - Shimla - Kufri - Manali - Gulaba - Dharamshala - Khajjari Dalhousie- HIMACHAL TOUR PACKAGE",
    "ASSAM & MEGHALAYA TOUR PACKAGE",
    "PUNJAB & HARYANA TOUR PACKAGE",
  ];

  const honeymoonTourPackages = [
    {
      name: "SIKKIM TOUR PACKAGE",
      url: "/honeymoon-tour-package",
    },
    {
      name: "HIMACHAL TOUR PACKAGE",
      url: "/honeymoon-tour-package",
    },
    {
      name: "SHIMLA TOUR PACKAGE",
      url: "/honeymoon-tour-package",
    },
    {
      name: "KODAIKANAL TOUR PACKAGE",
      url: "/honeymoon-tour-package",
    },
    {
      name: "TEKKADY TOUR PACKAGE",
      url: "/honeymoon-tour-package",
    },
    {
      name: "OOTY TOUR PACKAGE",
      url: "/honeymoon-tour-package",
    },
    {
      name: "MUNNAR TOUR PACKAGE",
      url: "/honeymoon-tour-package",
    },
    {
      name: "KASHMIR TOUR PACKAGE",
      url: "/honeymoon-tour-package",
    },
    {
      name: "TOURS PACKAGE FROM KODAIKANAL",
      url: "/honeymoon-tour-package",
    },
    {
      name: "MANIL TOUR PACKAGE",
      url: "/honeymoon-tour-package",
    },
    {
      name: "GOA TOUR PACKAGE",
      url: "/honeymoon-tour-package",
    },
  ];

  const darshanTourPackages = [
    {
      name: "MADURAI TEMPLE DARSHAN TOUR PACKAGE",
      url: "/darshan-tour-package",
    },
    {
      name: "MADURAI RAMESWARAM KANYAKUMARI TEMPLE DARSHAN TOUR PACKAGE",
      url: "/darshan-tour-package",
    },
    {
      name: "MADURAI RAMESWARAM TANJAVUR KUMBAKONAM TRICHY TEMPLE DARSHAN TOUR PACKAGE",
      url: "/darshan-tour-package",
    },
    {
      name: "COVAI MADURAI RAMESWARAM KANYAKUMARI THIRUVANANTHAPURAM TEMPLE DARSHAN TOUR PACKAGE",
      url: "/darshan-tour-package",
    },
    {
      name: "KUMBAKONAM NAVAGRAHA TEMPLE DARSHAN TOUR PACKAGE",
      url: "/darshan-tour-package",
    },
    {
      name: "TIRUPATHI TEMPLE DARSHAN TOUR PACKAGE",
      url: "/darshan-tour-package",
    },
    {
      name: "LORD MURUGAN KANTHASWAMY 06 HOUSE TEMPLE DARSHAN TOUR PACKAGE",
      url: "/darshan-tour-package",
    },
    {
      name: "CHOTTANIKARAI ERANAKULAM GURUVAYUR POLLACHI TEMPLE DARSHAN TOUR PACKAGE",
      url: "/darshan-tour-package",
    },
    {
      name: "TRICHY THIRUVANAMALAI KANCHIPURAM KALAKASTHI TIRUPATHI TEMPLE DARSHAN TOUR PACKAGE",
      url: "/darshan-tour-package",
    },
    {
      name: "SHIRDI TEMPLE DARSHAN TOUR PACKAGE",
      url: "/darshan-tour-package",
    },
    {
      name: "UTTAR PRADESH TEMPLE DARSHAN TOUR PACKAGE",
      url: "/darshan-tour-package",
    },
    {
      name: "GUJARAT TEMPLE DARSHAN TOUR PACKAGE",
      url: "/darshan-tour-package",
    },
    {
      name: "KASI TEMPLE DARSHAN TOUR PACKAGE",
      url: "/darshan-tour-package",
    },
    {
      name: "KEDARNATH TEMPLE DARSHAN TOUR PACKAGE",
      url: "/darshan-tour-package",
    },
  ];
  const footerData = {
    logo: {
      src: "/aajavologoimage.png",
      alt: "Aajavo Travels",
      description:
        "Best Travel, Tours and Car Services in Madurai. We offer special packages for North Indians, Pilgrims, Honeymoon Couples, and more. Services available across South India with One-Way, Two-Way, and Package Facilities.",
    },
    sections: [
      {
        title: "Information",
        links: [
          { name: "Home", url: "/" },
          { name: "Booking", url: "#" },
          { name: "About", url: "#" },
          { name: "Our Pricing", url: "#" },
          {
            name: "Accommodation",
            url: "/accomodation",
          },
          {
            name: "Terms & Conditions",
            url: "/terms-and-conditions",
          },
          {
            name: "Privacy Policy",
            url: "/privacy-policy",
          },
        ],
      },
      {
        title: "Packages",
        links: [
          {
            name: "Domestic Tour Package",
            url: "/domestic-tour-package",
          },
          {
            name: "International Tour Package",
            url: "/international-tour-package",
          },
          {
            name: "Darshan Tour Package",
            url: "/darshan-tour-package",
          },
          {
            name: "Honeymoon Tour Package",
            url: "/honeymoon-tour-package",
          },
          {
            name: "North India Tour Package",
            url: "/north-tour-package",
          },
          {
            name: "South India Tour Package",
            url: "/south-tour-package",
          },
        ],
        extra: {
          title: "Follow Us On",
          text: "GST : 33BOFPB1222K1ZJ",
        },
      },
    ],
    officialInfo: {
      title: "Official Info",
      details: [
        { label: "Head Office", value: "Madurai, Tamil Nadu, India", icon: <FaMapMarkerAlt /> },
        { label: "Branch Offices", value: "Trichy, Coimbatore, Bangalore, Chennai, Thiruvananthapuram, Cochin (Kerala)", icon: <FaMapMarkerAlt /> },
        { label: "Email", value: "travelsaajavo@gmail.com, aajavotravels@gmail.com", icon: <FaEnvelope /> },
        { label: "Phone", value: "9840426397,6380562390", icon: <FaPhoneAlt /> },
      ],
    },
  };
  const socialLinks = [
    {
      href: "https://www.facebook.com/Aajavotravels?mibextid=ZbWKwL",
      icon: <FaFacebook />,
    },
    {
      href: "https://www.instagram.com/aajavotravels/?igsh=ZGZhZWNmbTQ5d3lh",
      icon: <FaInstagram />,
    },
    { href: "https://www.youtube.com/@aajavotravels614", icon: <FaYoutube /> },
    {
      href: "https://twitter.com/aajavo?t=I_yFlvaNc6yUcjzCu9__3A&s=09",
      icon: <FaTwitter />,
    },
  ];
  return (
    <div className="bg-[#1a1a1a] pt-2 md:pt-4 text-center ">
      <div className="container mx-auto py-5 px-5">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-3 gap-x-3 gap-y-6 px-6 text-center">
          {/* Package from Top Cities */}
          <div className="col-span-2 lg:col-span-1">
            <h1 className="text-[#e30d16] text-[22px] sm:text-[23px] md:text-[24px] font-semibold">
              PACKAGE FROM TOP CITIES
            </h1>
            {topCitiesPackages.map((link, i) => (
              <div key={i} className="text-white mt-3 flex flex-col">
                <a
                  href={link.url}
                  className="text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block"
                >
                  {link.name}
                </a>
              </div>
            ))}
          </div>

          {/* South India Tour Packages */}
          <div className=" col-span-2">
            <h1 className="text-[#e30d16] text-[22px] sm:text-[23px] md:text-[24px] font-semibold">
              SOUTH INDIA TOURS PACKAGE
            </h1>
            {southIndiaPackages.map((link, i) => (
              <div key={i} className="text-white mt-2 flex flex-col">
                <a
                  href={link.url}
                  className="text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block"
                >
                  {link.name}
                </a>
              </div>
            ))}
          </div>
          <div className="col-span-2 lg:col-span-1">
            <h1 className="text-[#e30d16] text-[22px] sm:text-[23px] md:text-[24px] font-semibold">
              INTERNATIONAL TOUR PACKAGE
            </h1>
            {internationalPackages.map((link, i) => (
              <div key={i} className="text-white mt-3 flex flex-col">
                <a
                  href="/international-tour-package"
                  className="text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block"
                >
                  {link}
                </a>
              </div>
            ))}
          </div>
          <div className=" col-span-2">
            <h1 className="text-[#e30d16] text-[22px] sm:text-[23px] md:text-[24px] font-semibold">
              North India Tour Package
            </h1>
            {northIndiaPackages.map((link, i) => (
              <div key={i} className="text-white mt-2 flex flex-col">
                <a
                  href="/north-tour-package"
                  className="text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block"
                >
                  {link}
                </a>
              </div>
            ))}
          </div>
          <div className="col-span-2 lg:col-span-1">
            <h1 className="text-[#e30d16] text-[22px] sm:text-[23px] md:text-[24px] font-semibold">
              HONEY MOON TOUR PACAKAGE
            </h1>
            {honeymoonTourPackages.map((link, i) => (
              <div key={i} className="text-white mt-3 flex flex-col">
                <a
                  href={link.url}
                  className="text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block"
                >
                  {link.name}
                </a>
              </div>
            ))}
          </div>

          {/* South India Tour Packages */}
          <div className=" col-span-2">
            <h1 className="text-[#e30d16] text-[22px] sm:text-[23px] md:text-[24px] font-semibold">
              DHARSHA TOURS PACKAGES
            </h1>
            {darshanTourPackages.map((link, i) => (
              <div key={i} className="text-white mt-2 flex flex-col">
                <a
                  href={link.url}
                  className="text-[13px] lg:text-[11px] 2xl:text-[15px] font-medium mt-3 inline-block"
                >
                  {link.name}
                </a>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 mt-9 items-start gap-5 sm:gap-10 md:gap-16 lg:gap-20 text-left">
            {/* Logo & Description */}
            <div className="">
              <img
                src={footerData.logo.src}
                alt={footerData.logo.alt}
                className=" w-[180px] mx-auto bg-white"
              />
              <p className="text-white pt-5 font-[16px]">
                {footerData.logo.description}
              </p>
            </div>

            {/* Dynamic Sections */}
            {footerData.sections.map((section, index) => (
              <div key={index} className="text-[#fff]">
                <h4 className="text-[21px] sm:text-[23px] md:text-[25px]">
                  {section.title}
                </h4>
                <ul className="pt-2 md:pt-5 text-[#fff] text-[14px] footer-dot-par">
                  {section.links.map((link, i) => (
                    <li key={i} className="mt-1">
                      <a href={link.url} className="">
                        {link.name}
                      </a>
                    </li>
                  ))}
                </ul>
                {/* Extra Info (For Packages Section) */}
                {section.extra && (
                  <>
                    <h4 className="text-[21px] sm:text-[23px] md:text-[25px]">
                      {section.extra.title}
                    </h4>

                    <div className="flex gap-x-3 mt-2">
                      {socialLinks.map((social, index) => (
                        <a
                          key={index}
                          href={social.href}
                          target="__blank"
                          rel="noopener noreferrer"
                          className="text-white hover:text-yellow-400 text-2xl"
                        >
                          {social.icon}
                        </a>
                      ))}
                    </div>
                    <div className=" text-white mt-4">
                      <span className="mt-1">{section.extra.text}</span>
                    </div>
                  </>
                )}
              </div>
            ))}

            {/* Official Information */}
            <div className="text-white">
              <h4 className="text-[21px] sm:text-[23px] md:text-[25px]">
                {footerData.officialInfo.title}
              </h4>
              <div className="space-y-3 textpt-2 sm:pt-3 md:pt-4  sm:pl-0 md:pl-5">
                {footerData.officialInfo.details.map((info, index) => (
                  <div key={index}>
                    <span className="text-[18px] font-medium">
                      {info.label}:
                    </span>
                    <p className="text-[14px] flex gap-x-2 items-center">
                    <span className=" text-[17px]">{info.icon}</span>
                      <span className="">{info.value}</span>
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div class="fixed bottom-[55px] right-[40px] sm:right-[40px] md:right-[55px] z-10"><a href="https://wa.me/+916380562390" target="_blank"> <img src={whatsappImage} class="w-[40px] sm:w-[40px] md:w-[50px] lg:w-[60px]" alt="whatsapp_img" /></a></div>
        <div class=" fixed bottom-[55px] left-[40px] sm:left-[40px] md:left-[55px] z-10"><a href="tel:+916380562390"> <img src={phoneImage} class="w-[40px] sm:w-[40px] md:w-[50px] lg:w-[60px]" alt="whatsapp_img" /></a></div>
        <div class="fixed bottom-[135px] right-[40px]  sm:right-[40px] md:right-[55px] z-10 scrolll_btn"><a href="#" class="inline-block outline-4 outline-white outline  w-[35px] sm:w-[40px] md:w-[45px]  lg:w-[50px] h-[35px] sm:h-[40px] md:h-[45px] lg:h-[50px] bg-[#fcb708] hover:bg-[#000] transition-all ease-linear rounded-full relative"> <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="text-[16px]  w-[20px] h-[20px] text-black hover:text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"></path></svg></a></div>
        {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-3 gap-x-3 gap-y-6 px-6 text-center"> */}
        {/* Package from Top Cities */}

        {/* South India Tour Packages */}
      </div>
      {/* </div> */}
     
      {/* Footer */}
      <div class="text-white text-sm py-3 border-t border-gray-700">© Aajavo Travels All Rights Reserved || Designed By <a href="https://netbix.in" target="__blank" class="text-[#e30d16]">Netbix Technologies.</a></div>
    </div>
  );
};

export default Footer;
