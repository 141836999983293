import React from 'react'

import { Divider } from 'antd';

const ExclusivePackageCard = ({card_img,routes,link}) => {
  return (
    <div className='bg-white py-4 px-4 md:px-10 mr-0 sm:mr-2 md:mr-3 lg:mr-6 ex-cont'>
        <div className='pb-10 bg-red-300 ex-img' style={{backgroundImage:`url(${card_img})`}}>
        </div>
        <div className='route-card'>
            <h5>{routes}</h5>
        </div>
        {/*<Divider dashed style={{borderColor:"black"}}/>
        <button>Book Now</button>*/}
      <a href={link} target="__blank" class="bg-[#fcb708] w-full flex text-center justify-center items-center font-semibold mt-4 md:mt-6 text-black py-4 px-8  text-[16px] sm:text-[17px] md:text-[18px]  uppercase hover:text-white hover:bg-black  transition-all ease-linear">whataspp enquiry</a>
    </div>
  )
}

export default ExclusivePackageCard