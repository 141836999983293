import React, { useEffect, useRef, useState } from "react";
import selct_car from "../assets/images/selct_car.svg";
import sedan from "../assets/images/sedan.png";
import etios from "../assets/images/etios.png";
import suv from "../assets/images/suv.png";
import innova from "../assets/images/innova.png";
// import innova from "../assets/images/InnovaBgNone.png"
import { useForm } from "react-hook-form";
import { useLoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import axios from "axios";


import { AiOutlineCloseCircle } from "react-icons/ai";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdLuggage } from "react-icons/md";
import { FaUser } from "react-icons/fa6";
import { netbixTelegramChatId, telegramApi, telegramChatId, netbixTelegramApi } from "../config/config";
import { Loader } from "@googlemaps/js-api-loader";
const libraries = ["places"];

const Roundtrip = ({ tripType, fromChange, mobileNumber, setMobileNumber }) => {
  const [selectedDate, setSelectedDate] = useState("");
  // const [finalMobileNumber, setFinalNumber] = useState();
  const [roundeDriverBeta, setRoundeDriverBeta] = useState(400);
  const [selectedReturnDate, setSelectedReturnDate] = useState(null);
  const [driverBeta, setDeriverBeta] = useState(0);
  const [carList, setCarList] = useState(false);
  const [listCars, setListCars] = useState({ car_amount: 0 });
  const [carReq, setCarReq] = useState(false);
  const [distanceResult, setDistanceResult] = useState({
    org_distance: null,
    org_duration: null,
  });
  const [google, setGoogle] = useState(null);
  const [whatsaappMsg, setWhatsaappMsg] = useState("");
  const [carImages, setCarImages] = useState(null);

  const [popup, setPopup] = useState(false);

  const [popupDis, setPopupDis] = useState(0);
  const [popupDur, setPopupDur] = useState("");
  const [popupCar, setPopupCar] = useState("");
  const [popupRate, setPopupRate] = useState(0);
  const [totRate, setTotalRate] = useState(0);
  function openCars() {
    setCarList(!carList);
  }
  const searchBox = useRef(null);

  const formRef = useRef(null);

  function sendWhatsapp() {
    document.body.classList.remove("dis_scroll");
    setPopup(false);
    window.open("https://wa.me/+919840426397?text=" + whatsaappMsg);
  }

  const handlePlacesChanged = () => {
    const places = searchBox.current.getPlaces();
    console.log(places);
  };

  // const { isLoaded, loadError } = useLoadScript({
  //     googleMapsApiKey: 'AIzaSyCYfVYTCGKRhBNN5v6uIqb3C477IWcVcCU',
  //     libraries,
  // });

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
    clearErrors
  } = useForm();

  useEffect(() => {
    if (window.google) {
      setGoogle(window.google);
    }
  }, []);
  const inputRef = useRef(null);
  const inputRefDrop = useRef(null);
  const [loadingButton, setBoadingButton] = useState(true);
  useEffect(() => {
      // Load Google Maps API
      const loader = new Loader({
        apiKey: "AIzaSyCyxa0d5VJiM4MCBrdLrSCa_CRA0TTYhpA", // Replace with your API key
        libraries: ["places"], // Include the places library
      });
  
      loader.load().then(() => {
        const southIndiaBounds = {
          north: 13.0, // Adjusted northern boundary of South India
          south: 8.0,  // Southern boundary (Kanyakumari)
          west: 73.0,  // Western boundary (Kerala and Karnataka)
          east: 80.5,  // Eastern boundary (Andhra Pradesh and Tamil Nadu)
        };
  
        // Initialize Autocomplete
        const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
          bounds: southIndiaBounds,         // Set South India bounds
          strictBounds: true,               // Enforce bounds
          componentRestrictions: { country: "IN" }, // Restrict to India
        });
  
        const autocompleteDrop = new window.google.maps.places.Autocomplete(inputRefDrop.current, {
          bounds: southIndiaBounds,         // Set South India bounds
          strictBounds: true,               // Enforce bounds
          componentRestrictions: { country: "IN" }, // Restrict to India
        });
  
        autocompleteDrop.addListener("place_changed", () => {
  
          const place = autocompleteDrop.getPlace();
  
          if (place && place.geometry) {
            setValue('drop_add', place);
            clearErrors('drop_add', place);
            console.log("Selected place:", place);
  
          } else {
            console.log("No details available for this selection.");
          }
        });
  
        // Event listener for when a place is selected
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          if (place && place.geometry) {
            console.log("Selected place:", place);
            setValue('pick_up_add', place);
            clearErrors('pick_up_add', place);
  
  
            console.log("Selected place geometry:", place.geometry);
  
  
          } else {
            console.log("No details available for this selection.");
          }
        });
      });
    }, []);
  const onSubmit = async (data) => {

    if (listCars.car_amount === 0) {
      setCarReq(true);
      return;
    } else {
      setCarReq(false);
    }
    if (
      listCars.car_amount !== "0" &&
      data.drop_add !== "" &&
      data.full_name !== "" &&
      data.pick_up_add !== "" &&
      selectedDate !== "" &&
      data.pickup_time !== "" &&
      selectedReturnDate !== ""
    ) {
      if (data.drop_add === data.pick_up_add) {
        return alert("Please select Different Places");
      }
      if (!google) {
        alert("Google Maps API is not loaded yet.");
        return;
      }
      setPopupCar(listCars.text);
      const getLatLng = (address) => {
        return new Promise((resolve, reject) => {
          if (address instanceof google.maps.LatLng) {
            resolve(address);
          } else if (typeof address === "string") {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ address }, (results, status) => {
              if (status === "OK" && results[0]) {
                resolve(results[0].geometry.location);
              } else {
                reject(new Error(`Geocode failed for address: ${address}, reason: ${status}`));
              }
            });
          } else {
            reject(new Error("Invalid address or LatLng object"));
          }
        });
      };
      try {
        const pickUpAddress = typeof data.pick_up_add === "string" ? data.pick_up_add : data.pick_up_add?.formatted_address || "";
        const dropOffAddress = typeof data.drop_add === "string" ? data.drop_add : data.drop_add?.formatted_address || "";
      
              // Only proceed if both addresses are valid
              if (!pickUpAddress || !dropOffAddress) {
                alert("Invalid address provided. Please check the addresses.");
                setBoadingButton(false);
                return;
              }
      
              // Get LatLng for pick-up and drop-off locations
              const pickUpLocation = await getLatLng(pickUpAddress);
              const dropOffLocation = await getLatLng(dropOffAddress);
      
              const distanceMatrixService = new google.maps.DistanceMatrixService();
              const distanceMatrixOptions = {
                origins: [pickUpLocation],
                destinations: [dropOffLocation],
                travelMode: "DRIVING",
              };
      

      await distanceMatrixService.getDistanceMatrix(
        distanceMatrixOptions,
        (response, status) => {
          if (
            status === "OK" &&
            response.destinationAddresses[0].length !== 0 &&
            response.originAddresses[0].length !== 0
          ) {
            if (
              response.rows[0].elements[0].distance.text ||
              response.rows[0].elements[0].duration.text
            ) {
              var org_distance = response.rows[0].elements[0].distance.text;
              var org_duration = response.rows[0].elements[0].duration.text;
              setPopupDur(org_duration);
              setPopupDis(org_distance);
              setDistanceResult({ org_distance, org_duration });

              if (org_distance != null && org_duration != null) {
                var day = selectedDate.getDate();
                console.log(day);
                var month = selectedDate.getMonth() + 1;
                console.log(month);

                var year = selectedDate.getFullYear();
                console.log(year);
                var formattedDate = day + '/' + month + '/' + year;
                console.log(formattedDate);

                var day1 = selectedReturnDate.getDate();
                console.log(day1);
                var month1 = selectedReturnDate.getMonth() + 1;
                console.log(month1);

                var year1 = selectedReturnDate.getFullYear();
                console.log(year1);
                var formattedDate2 = day1 + '/' + month1 + '/' + year1;
                console.log(formattedDate2);

                var today = formattedDate;
                today = new Date(
                  today.toString().split("/")[2],
                  today.toString().split("/")[1] - 1,
                  today.toString().split("/")[0]
                );
                console.log(today);
                var date2 = formattedDate2
                date2 = new Date(
                  date2.toString().split("/")[2],
                  date2.toString().split("/")[1] - 1,
                  date2.toString().split("/")[0]
                );
                console.log(date2);
                var timeDiff = Math.abs(date2.getTime() - today.getTime());
                var diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

                var newdiffDays = diffDays + 1;
                var DriverBetaMul = newdiffDays * roundeDriverBeta;
                setDeriverBeta(DriverBetaMul);
                if (newdiffDays === 1) {
                  var dayskm = parseInt(org_distance) * 2 ;
                  if (dayskm <= 250) {
                    var one_way_rate = ((250 * newdiffDays) * parseInt(listCars.car_amount));
                    setTotalRate(250 * parseInt(listCars.car_amount));
                    setPopupRate(parseInt(one_way_rate) + DriverBetaMul)

                  }
                  else {
                    var one_way_rate = (dayskm * parseInt(listCars.car_amount));
                    setTotalRate(dayskm * parseInt(listCars.car_amount));
                    setPopupRate(parseInt(one_way_rate) + DriverBetaMul)
                  }
                  var new_rate_round_trip = one_way_rate;
                  var new_tot_trip_fair = parseInt(one_way_rate) + DriverBetaMul;
                  console.log(new_rate_round_trip);

                }
                else {
                   var daysprice = 250 * newdiffDays;
                   var dayskm = parseInt(org_distance) * 2 ;
                   console.log(parseInt(org_distance),'distance km');
                   console.log(newdiffDays,'days');
                   
                   console.log(daysprice);
                   console.log(dayskm);
                   if(dayskm > daysprice){
                    var one_way_rate =(dayskm  * parseInt(listCars.car_amount));
                    setTotalRate((parseInt(org_distance) * 2)  * parseInt(listCars.car_amount));
                    setPopupRate(parseInt(one_way_rate) + DriverBetaMul);
                    console.log(one_way_rate,'daysprice1');
                    
                   }
                   else{
                    var one_way_rate = daysprice * parseInt(listCars.car_amount);
                    setTotalRate(daysprice * parseInt(listCars.car_amount));
                    setPopupRate(parseInt(one_way_rate) + DriverBetaMul);
                    console.log(one_way_rate,'daysprice2');                    
                   }
                   var new_rate_round_trip = one_way_rate;
                   var new_tot_trip_fair = parseInt(one_way_rate) + DriverBetaMul;
                   console.log(new_rate_round_trip);
                }
                var front_url = "https://aajavotravels.in/";
                const formattedPickUp = pickUpAddress || (data.pick_up_add?.formatted_address || "Unknown Location");
                const formattedDropOff = dropOffAddress || (data.drop_add?.formatted_address || "Unknown Location");
                setWhatsaappMsg(
                  "Website Enquiry%0A******* *******%0AYour Booking Details:%0A%0AName : " +
                  data.full_name +
                  " ,%0A%0AMobile Number  : +91" +
                  data.mob_number +
                  " ,%0A%0APickup Location  : " +
                  formattedPickUp +
                  " ,%0A%0ADrop Location : " +
                  formattedDropOff +
                  ",%0A%0ATrip Type: Round Trip ,%0A%0APickup Date/Time: " +
                  formattedDate +
                  " " +
                  data.pickup_time +
                  ",%0A%0AReturn Date : " +
                  formattedDate2 +
                  " ,%0A%0ATotal KM : " +
                  org_distance +
                  " ,%0A%0ADuration : " +
                  org_duration +
                  // " ,%0A%0APassengers  : " +
                  // data.passengers +
                  // " ,%0A%0ALuggages  : " +
                  // data.luggages +
                  " ,%0A%0ARate Per KM: " +
                  listCars.car_amount +
                  " ₹,%0A%0ADriver Beta : " +
                  DriverBetaMul +
                  // " ₹  ,%0A%0ARate for Round Trip : " +
                  // new_rate_round_trip +
                  "  ₹,%0A%0ATotal Trip Fare :" +
                  new_tot_trip_fair +
                  " ₹ ,%0A%0AToll, parking, permit extra %0A%0AFor any questions please contact +919342426541 %0A%0A" +
                  front_url
                );
                var message =
                  "Website Enquiry\n******* *******\n\nYour Booking Details:\n\nName : " +
                  data.full_name +
                  " ,\n\nMobile Number  : +91" +
                  data.mob_number +
                  " ,\n\nPickup Location  : " +
                  formattedPickUp +
                  " ,\n\nDrop Location : " +
                  formattedDropOff +
                  // " ,\n\nPassengers : " +
                  // data.passengers +
                  // " ,\n\nLuggages : " +
                  // data.luggages +
                  ",\n\nTrip Type: Round Trip ,\n\nPickup Date/Time: " +
                  formattedDate +
                  " " +
                  data.pickup_time +
                  ",\n\nReturn Date : " +
                  formattedDate2 +
                  "  , \n\nTotal KM : " +
                  org_distance +
                  " ,\n\nDuration : " +
                  org_duration +
                  " ,\n\nRate Per KM: " +
                  listCars.car_amount +
                  " ₹,\n\nDriver Beta : " +
                  DriverBetaMul +
                  // " ₹  ,\n\nRate for Round Trip : " +
                  // new_rate_round_trip +
                  "  ₹,\n\nTotal Trip Fare :" +
                  new_tot_trip_fair +
                  " ₹ ,\n\nToll, parking, permit extra \n\nFor any questions please contact +919840426397 \n\n" +
                  front_url;
                try {
                  const response = axios.post(
                    `https://api.telegram.org/bot${telegramApi}/sendMessage`,
                    {
                      chat_id: telegramChatId,
                      text: message,
                    }
                  );
                  if (response) {
                    setListCars({
                      text: "",
                      car_amount: 0,
                    });
                    formRef.current.reset();
                    document.body.classList.add("dis_scroll");
                    setPopup(true);
                    setSelectedDate(null);
                    setSelectedReturnDate(null);

                  }
                } catch (error) {
                  alert("Please try again");
                  console.error("Error sending Telegram message:", error);
                  // Handle the error
                }
                try {
                  axios.post(
                    `https://api.telegram.org/bot${netbixTelegramApi}/sendMessage`,
                    {
                      chat_id: netbixTelegramChatId,
                      text: message,
                    }
                  );
                  //   if (response) {
                  //     setListCars({
                  //       text: "",
                  //       imageSrc: "",
                  //       car_amount: 0,
                  //     });
                  //     formRef.current.reset();
                  //     document.body.classList.add("dis_scroll");
                  //     setPopup(true);
                  //     setSelectedDate(null);
                  //     setSelectedReturnDate(null);
  
                  // }  
                } catch (error) {
                  console.error("Error sending Telegram message:", error);
                  // Handle the error
                }

              } else {
                alert("Please Try again!");
              }
            }
          } else {
            console.error("Error:", status);
          }
        }
      );
    } catch (error) {
      setBoadingButton(false);
      alert("Error in geocoding addresses.");
      console.error("Error geocoding addresses:", error);
    }
    }
  };
  function popUpClose() {
    document.body.classList.remove("dis_scroll");
    // setMobileNumber
    setPopup(false);
  }
  const selectCar = (text, car_amount, carDriverBeta) => {
    setListCars({
      text,
      car_amount,
    });
    setRoundeDriverBeta(carDriverBeta);
    setCarList(false);
    setCarImages(text);

  };

  return (
    <div className="mt-6 ">
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="p-3">
            <input
              type="text"
              className="w-full py-3 px-4"
              placeholder="Full Name"
              name="full_name"
              {...register("full_name", { required: true })}
            />
            {errors.full_name && (
              <span className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">
                Name is required.
              </span>
            )}
          </div>
          <div className="p-3">
            <input
              type="number"
              className="w-full py-3 px-4"
              placeholder="Mobile number"
              name="mob_number"
              {...register("mob_number", { required: true })}
            />
            {errors.mob_number && (
              <span className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">
                Mobile Number is required.
              </span>
            )}
          </div>
        </div>
        < div className="grid grid-cols-2">
        <div className="p-3 ">
            <input
              type="text"
              ref={inputRef}
              name='pick_up_add'
              placeholder="Enter pick-up location"
              // className="form-control" 
              className={`w-full py-3 px-4 focus:outline-0 border  ${errors.drop_add &&
                errors.pick_up_add &&
                " border-red-400 border-t-0"
                } ${errors.drop_add && !errors.pick_up_add && " border-red-400 "
                }`} />
            {errors.pick_up_add && (
              <p className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">{errors.pick_up_add.message}</p>
            )}

            {/* Hidden Input for Form Data */}
            <input
              type="hidden"
              placeholder="Enter pick-up location"
              className="form-control"
              ref={inputRef}
              {...register("pick_up_add", { required: "Field is required" })}
            />

          </div>
          {/* drop_add */}
          <div className="p-3 ">
            <input
              type="text"
              ref={inputRefDrop}
              name='drop_add'
              placeholder="Enter Drop-off Location"
              className={`w-full py-3 px-4 focus:outline-0 border  ${errors.drop_add &&
                errors.drop_add &&
                " border-red-400 border-t-0"
                } ${errors.drop_add && !errors.drop_add && " border-red-400 "
                }`}
            />
            {errors.drop_add && (
              <p className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">{errors.drop_add.message}</p>
            )}
            <input
              type="hidden"
              placeholder="Enter Drop-off Location"
              className={`w-full py-3 px-4 focus:outline-0 border  ${errors.drop_add &&
                errors.drop_add &&
                " border-red-400 border-t-0"
                } ${errors.drop_add && !errors.drop_add && " border-red-400 "
                }`}
              ref={inputRefDrop}
              {...register("drop_add", { required: "Field is required" })}
            />
          </div>
          </div>
          <div className=" grid grid-cols-2">
            <div className="p-3">
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                dateFormat="dd/MM/yyyy"
                minDate={new Date()}
                className={`w-full py-3 px-4 focus:outline-0 ${!selectedDate &&
                  errors.pickup_dates &&
                  "border border-red-500"

                  }`}
                placeholderText="Pick up Date"
                name="pickup_date"
                inputMode="none"
                onFocus={(e) => e.target.blur()} 
              />


              <input
                type="hidden"
                value={selectedDate ? selectedDate : ' '}
                name="pickup_dates"
                {...register("pickup_dates", { required: true })}
              />
            </div>
            <div className="p-3">
              <select
                className={`w-full py-3 px-4 focus:outline-0 ${errors.pickup_time && " border border-red-500"
                  }`}
                id="pickup_time"
                name="pickup_time"
                {...register("pickup_time", { required: true })}
              >
                <option value="">Pickup Time</option>

                <option value="12:00 AM">12:00 AM</option>

                <option value="12:30 AM">12:30 AM</option>

                <option value="01:00 AM">01:00 AM</option>

                <option value="01:30 AM">01:30 AM</option>

                <option value="02:00 AM">02:00 AM</option>

                <option value="02:30 AM">02:30 AM</option>

                <option value="03:00 AM">03:00 AM</option>

                <option value="03:30 AM">03:30 AM</option>

                <option value="04:00 AM">04:00 AM</option>

                <option value="04:30 AM">04:30 AM</option>

                <option value="05:00 AM">05:00 AM</option>

                <option value="05:30 AM">05:30 AM</option>

                <option value="06:00 AM">06:00 AM</option>

                <option value="06:30 AM">06:30 AM</option>

                <option value="07:00 AM">07:00 AM</option>

                <option value="07:30 AM">07:30 AM</option>

                <option value="08:00 AM">08:00 AM</option>

                <option value="08:30 AM">08:30 AM</option>

                <option value="09:00 AM">09:00 AM</option>

                <option value="09:30 AM">09:30 AM</option>

                <option value="10:00 AM">10:00 AM</option>

                <option value="10:30 AM">10:30 AM</option>

                <option value="11:00 AM">11:00 AM</option>

                <option value="11:30 AM">11:30 AM</option>

                <option value="12:00 PM">12:00 PM</option>

                <option value="12:30 PM">12:30 PM</option>

                <option value="01:00 PM">01:00 PM</option>

                <option value="01:30 PM">01:30 PM</option>

                <option value="02:00 PM">02:00 PM</option>

                <option value="02:30 PM">02:30 PM</option>

                <option value="03:00 PM">03:00 PM</option>

                <option value="03:30 PM">03:30 PM</option>

                <option value="04:00 PM">04:00 PM</option>

                <option value="04:30 PM">04:30 PM</option>

                <option value="05:00 PM">05:00 PM</option>

                <option value="05:30 PM">05:30 PM</option>

                <option value="06:00 PM">06:00 PM</option>

                <option value="06:30 PM">06:30 PM</option>

                <option value="07:00 PM">07:00 PM</option>

                <option value="07:30 PM">07:30 PM</option>

                <option value="08:00 PM">08:00 PM</option>

                <option value="08:30 PM">08:30 PM</option>

                <option value="09:00 PM">09:00 PM</option>

                <option value="09:30 PM">09:30 PM</option>

                <option value="10:00 PM">10:00 PM</option>

                <option value="10:30 PM">10:30 PM</option>

                <option value="11:00 PM">11:00 PM</option>

                <option value="11:30 PM">11:30 PM</option>
              </select>
            </div>
          </div>
          {/* <div className=" border mx-3  py-3 pl-6 border-t-0 bg-black">
            <span
              onClick={() => tripType(!fromChange)}
              className="bg-[#ff9a00] py-2 px-3 rounded-3xl text-white text-sm cursor-pointer"
            >
              One Way?
            </span>
          </div> */}
          {/* <div className=" grid grid-cols-2 border mx-3 mt-3">
            <div className=" relative">
              <span className=" absolute top-1/2 left-2 -translate-y-1/2 text-white">
                <FaUser className=" " />
              </span>
              <select
                className={`w-full px-4 pl-7  py-3 focus:outline-0 appearance-none ${
                  errors.passengers && " border border-red-500"
                }`}
                id="pickup_time"
                name="passengers"
                {...register("passengers", { required: true })}
              >
                <option selected value="1">
                  1
                </option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </select>
            </div>
            <div className=" relative">
              <span className=" absolute top-1/2 left-2 -translate-y-1/2 text-white">
                <MdLuggage size={20} className=" " />
              </span>
              <select
                className={`w-full px-4 pl-7  py-3 focus:outline-0 appearance-none ${
                  errors.luggages && " border border-red-500"
                }`}
                id="pickup_time"
                name="luggages"
                {...register("luggages", { required: true })}
              >
                <option selected value="0">
                  0
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </select>
            </div>
          </div> */}
          <div className="grid grid-cols-2">
          <div className="p-3">
            <DatePicker
              selected={selectedReturnDate}
              onChange={(date) => setSelectedReturnDate(date)}
              dateFormat="dd/MM/yyyy"
              minDate={new Date()}
              className={`w-full py-3 px-4  focus:outline-0 ${!selectedReturnDate &&
                errors.return_date &&
                "border border-red-500"
                }`}
              placeholderText="Return Date"
              name="return_date"
              inputMode="none"
                onFocus={(e) => e.target.blur()} 
            />


            <input
              type="hidden"
              value={selectedReturnDate ? selectedReturnDate : ' '}
              name="return_date"
              {...register("return_date", { required: true })}
            />
            {/* { (
              <span className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">
                Pickup Date is required.
              </span>
            )} */}
          </div>
          <div></div>
          </div>

       
       
        <div className='p-3'>
    <input type="hidden" name='car_amount' value={listCars.car_amount !== 0 ? listCars.car_amount : 0} />
    
    {/* Car Selection Dropdown */}
    <div className='p-3 px-4 bg-white cursor-pointer relative' onClick={openCars}>
        {listCars.text ? listCars.text : 'Select car Type'} 
        <img src={listCars.imageSrc ? listCars.imageSrc : selct_car} alt="select car" className='w-[35px] h-auto absolute top-1/2 right-4 -translate-y-1/2' />
    </div>

    {/* Dropdown List */}
    <ul className={`bg-white border ${carList ? '' : 'hidden'}`}>
        <li className='py-3 px-4 border-b-2 relative cursor-pointer' onClick={() => selectCar('SEDAN', 12, 400)}>
            SEDAN <img src={sedan} alt="sedan" className='absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto' />
        </li>
        <li className='py-3 px-4 border-b-2 relative cursor-pointer' onClick={() => selectCar('ETIOS', 12, 400)}>
            MINI <img src={etios} alt="etios" className='absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto' />
        </li>
        <li className='py-3 px-4 border-b-2 relative cursor-pointer' onClick={() => selectCar('SUV', 16, 400)}>
            SUV <img src={suv} alt="suv" className='absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto' />
        </li>
        <li className='py-3 px-4 border-b-2 relative cursor-pointer' onClick={() => selectCar('INNOVA', 17, 400)}>
            INNOVA <img src={innova} alt="innova" className='absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto' />
        </li>
    </ul>

    {/* Validation Message */}
    {carReq && (
        <span className='mt-2 inline-block bg-red-500 w-full p-1 px-2 rounded-md text-white'>
            Car is required.
        </span>
    )}
</div>
          {/* <input
            type="hidden"
            name="car_amount"
            value={listCars.car_amount != 0 ? listCars.car_amount : 0}
          />
          <div
            className={`p-3 px-4 cursor-pointer relative border bg-black text-white ${
              carReq && "border-red-500"
            }`}
            onClick={openCars}
          >
            {listCars.text ? listCars.text : "Select Car Type"}{" "}
            <img
              src={listCars.imageSrc ? listCars.imageSrc : selct_car}
              alt="select car"
              className=" w-[35px] h-auto absolute top-1/2 right-4 -translate-y-1/2"
            />
          </div>
          <ul className={`bg-black text-white border ${carList ? "" : "hidden"}`}>
            <li
              className="py-3 px-4 border-b-2 relative cursor-pointer"
              onClick={() => selectCar("SEDAN", sedan, 13, 400)}
            >
              SEDAN{" "}
              <img
                src={sedan}
                alt="sedan"
                className="absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto"
              />
            </li>
            <li
              className="py-3 px-4 border-b-2 relative cursor-pointer"
              onClick={() => selectCar("ETIOS", etios, 13, 400)}
            >
              ETIOS{" "}
              <img
                src={etios}
                alt="etios"
                className="absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto"
              />
            </li>
            <li
              className="py-3 px-4 border-b-2 relative cursor-pointer"
              onClick={() => selectCar("SUV", suv, 18, 400)}
            >
              SUV{" "}
              <img
                src={suv}
                alt="suv"
                className="absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto"
              />
            </li>
            <li
              className="py-3 px-4 border-b-2 relative cursor-pointer"
              onClick={() => selectCar("INNOVA", innova, 19, 400)}
            >
              INNOVA{" "}
              <img
                src={innova}
                alt="innova"
                className="absolute top-1/2 right-4 -translate-y-1/2 w-[35px] h-auto"
              />
            </li>
          </ul> */}
          {/* {carReq ?

            <span className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">
              Car is required.
            </span> : " "
          }

        </div> */}
            <div className='text-center mt-5'>
                    <button className='bg-[#fcb708] font-semibold mt-4 md:mt-6 text-black py-3 px-6  text-[16px] sm:text-[17px] md:text-[18px] inline-block uppercase hover:text-white hover:bg-black transition-all ease-linear'>Get Estimation</button>
                </div>
      </form>
      {/* <form ref={formRef} onSubmit={onSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="p-3">
            <input
              type="text"
              className="w-full py-3 px-4 border border-gray-600"
              placeholder="Full Name"
              name="full_name"
              {...register("full_name", { required: true })}
            />
            {errors.full_name && (
              <span className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">
                Name is required.
              </span>
            )}
          </div>
          <div className="p-3">
            <input
              type="number"
              className="w-full py-3 px-4 border border-gray-600"
              placeholder="Mobile number"
              name="mob_number"
              {...register("mob_number", { required: true })}
            />
            {errors.mob_number && (
              <span className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">
                Mobile Number is required.
              </span>
            )}
          </div>
          <div className="p-3">
            <StandaloneSearchBox
              onLoad={(ref) => (searchBox.current = ref)}
              onPlacesChanged={handlePlacesChanged}
            >
              <input
                type="text"
                className="w-full py-3 px-4 border border-gray-600"
                placeholder="Pick up address"
                name="pick_up_add"
                {...register("pick_up_add", { required: true })}
              />
            </StandaloneSearchBox>
            {errors.pick_up_add && (
              <span className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">
                Pick up Address is required.
              </span>
            )}
          </div>
          <div className="p-3">
            <StandaloneSearchBox
              onLoad={(ref) => (searchBox.current = ref)}
              onPlacesChanged={handlePlacesChanged}
            >
              <input
                type="text"
                className="w-full py-3 px-4 border border-gray-600"
                placeholder="Drop off address"
                name="drop_add"
                {...register("drop_add", { required: true })}
              />
            </StandaloneSearchBox>
            {errors.drop_add && (
              <span className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">
                Drop Adress is required.
              </span>
            )}
          </div>
          <div className="p-3">
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="dd/MM/yyyy"
              className="w-full py-3 px-4 border border-gray-600"
              placeholderText="Pick up Date"
              minDate={new Date()}
              name="pickup_date"
            />
            <input
              type="hidden"
              value={selectedDate}
              name="pickup_dates"
              {...register("pickup_dates", { required: true })}
            />

            {!selectedDate && errors.pickup_dates && (
              <span className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">
                Pickup Date is required.
              </span>
            )}
          </div>
          <div className="p-3">
            <select
              className="w-full py-3 px-4 border border-gray-600"
              id="pickup_time"
              name="pickup_time"
              {...register("pickup_time", { required: true })}
            >
              <option value="">Pickup Time</option>

              <option value="12:00 AM">12:00 AM</option>

              <option value="12:30 AM">12:30 AM</option>

              <option value="01:00 AM">01:00 AM</option>

              <option value="01:30 AM">01:30 AM</option>

              <option value="02:00 AM">02:00 AM</option>

              <option value="02:30 AM">02:30 AM</option>

              <option value="03:00 AM">03:00 AM</option>

              <option value="03:30 AM">03:30 AM</option>

              <option value="04:00 AM">04:00 AM</option>

              <option value="04:30 AM">04:30 AM</option>

              <option value="05:00 AM">05:00 AM</option>

              <option value="05:30 AM">05:30 AM</option>

              <option value="06:00 AM">06:00 AM</option>

              <option value="06:30 AM">06:30 AM</option>

              <option value="07:00 AM">07:00 AM</option>

              <option value="07:30 AM">07:30 AM</option>

              <option value="08:00 AM">08:00 AM</option>

              <option value="08:30 AM">08:30 AM</option>

              <option value="09:00 AM">09:00 AM</option>

              <option value="09:30 AM">09:30 AM</option>

              <option value="10:00 AM">10:00 AM</option>

              <option value="10:30 AM">10:30 AM</option>

              <option value="11:00 AM">11:00 AM</option>

              <option value="11:30 AM">11:30 AM</option>

              <option value="12:00 PM">12:00 PM</option>

              <option value="12:30 PM">12:30 PM</option>

              <option value="01:00 PM">01:00 PM</option>

              <option value="01:30 PM">01:30 PM</option>

              <option value="02:00 PM">02:00 PM</option>

              <option value="02:30 PM">02:30 PM</option>

              <option value="03:00 PM">03:00 PM</option>

              <option value="03:30 PM">03:30 PM</option>

              <option value="04:00 PM">04:00 PM</option>

              <option value="04:30 PM">04:30 PM</option>

              <option value="05:00 PM">05:00 PM</option>

              <option value="05:30 PM">05:30 PM</option>

              <option value="06:00 PM">06:00 PM</option>

              <option value="06:30 PM">06:30 PM</option>

              <option value="07:00 PM">07:00 PM</option>

              <option value="07:30 PM">07:30 PM</option>

              <option value="08:00 PM">08:00 PM</option>

              <option value="08:30 PM">08:30 PM</option>

              <option value="09:00 PM">09:00 PM</option>

              <option value="09:30 PM">09:30 PM</option>

              <option value="10:00 PM">10:00 PM</option>

              <option value="10:30 PM">10:30 PM</option>

              <option value="11:00 PM">11:00 PM</option>

              <option value="11:30 PM">11:30 PM</option>
            </select>
            {errors.pickup_time && (
              <span className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">
                Return Date is required.
              </span>
            )}
          </div>
          <div className="p-3">
            <DatePicker
              selected={selectedReturnDate}
              onChange={(date) => setSelectedReturnDate(date)}
              dateFormat="dd/MM/yyyy"
              className="w-full py-3 px-4 border border-gray-600"
              minDate={new Date()}
              placeholderText="Return Date"
              name="return_date"
            />
            <input
              type="hidden"
              value={selectedReturnDate}
              name="return_dates"
              {...register("return_dates", { required: true })}
            />
            {!selectedReturnDate && errors.return_dates && (
              <span className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">
                Pickup Date is required.
              </span>
            )}
          </div>
        </div>
        <div className="p-3">
          <input
            type="hidden"
            name="car_amount"
            value={listCars.car_amount != 0 ? listCars.car_amount : 0}
          />
          <div
            className="p-3 px-4 bg-white cursor-pointer relative border border-gray-600"
            onClick={openCars}
          >
            {listCars.text ? listCars.text : "Select car Type"}{" "}
            <img
              src={listCars.imageSrc ? listCars.imageSrc : selct_car}
              alt="select car"
              className=" w-[35px] h-auto absolute top-1/2 right-4 -translate-y-1/2"
            />
          </div>
         
          {carReq ? (
            <span className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">
              Car is required.
            </span>
          ) : (
            ""
          )}
        </div>
        <div className="text-center mt-5">
          <button className="bg-[#ff9a00] mt-4 md:mt-6 text-white py-3 px-6  text-[16px] sm:text-[17px] md:text-[18px] inline-block uppercase hover:bg-black transition-all ease-linear">
            Get Estimation
          </button>
        </div>
      </form> */}
         {popup ? <div className='fixed top-0 left-0  h-full w-full z-[100]'>
                <div className='w-[90%] md:w-3/5 min-h-[200px] bg-gray-400 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute p-5'>
                    <span className='fixed top-2 right-2 cursor-pointer' onClick={popUpClose}>
                        <AiOutlineCloseCircle className='text-[20px]' />
                    </span>
                    <h2 className='text-[30px]'>Trip Estimation <span className='text-[#e30d16]'> Rs.{popupRate}</span></h2>
                    <div>
                        <div className='grid grid-cols-2 py-3 mt-4 '>
                            <div className='pl-2 border-b-2 border-t-2 border-l-2 py-3'>
                                Total Distance :
                            </div>
                            <div className='pr-2 border-b-2  border-t-2 border-r-2 border-l-2 px-2 py-3'>
                                {popupDis}
                            </div>
                            <div className='pl-2 border-b-2 border-l-2 py-3'>
                                Total Duration
                            </div>
                            <div className='pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3'>
                                {popupDur}
                            </div>
                            <div className='pl-2 border-b-2 border-l-2 py-3'>
                                Selected Car Type
                            </div>
                            <div className='pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3'>
                                {popupCar}
                            </div>
                            <div className='pl-2 border-b-2 border-l-2 py-3'>
                                Driver Allowance :
                            </div>
                            <div className='pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3'>
                                Included
                            </div>
                            <div className='pl-2 border-b-2 border-l-2 py-3'>
                                Total Amount :
                            </div>
                            <div className='pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3'>
                                Rs. {totRate}
                            </div>
                            <div className='pl-2 border-b-2 border-l-2 py-3'>
                                Driver Beta :
                            </div>
                            <div className='pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3'>
                                Rs. 400
                            </div>
                            <div className='pl-2 border-b-2 border-l-2 py-3'>
                                Total Driver Beta :
                            </div>
                            <div className='pr-2 border-b-2 border-r-2 border-l-2 px-2 py-3'>
                                Rs. {driverBeta}
                            </div>
                        </div>
                        <div>
                            <p>Below 250 Km for this round trip package only 6 hrs time allowed after reached hrs extra amount is applicable</p>
                            <p>Better for 250km try Hourly Packages</p>
                            <p>Toll,Parking,Hills,permit charges exluded</p>
                        </div>
                        <div className='mt-4 text-center'>
                            <button onClick={sendWhatsapp} className='bg-[#e30d16] py-3 text-white px-6 rounded-md'>Book Now</button>
                        </div>
                    </div>
                </div>
            </div> : ''}
    </div>
  );
};

export default Roundtrip;
