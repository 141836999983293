
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from "./components/Home";
import Page from './components/Page';
import banner1 from "./assets/pageBanners/madurai.jpg"
import banner2 from "./assets/pageBanners/thekkady.jpg"
import banner3 from "./assets/pageBanners/munnar.jpeg"
import banner4 from "./assets/touristPlaces/chennai.jpg"
import banner5 from "./assets/touristPlaces/banglore2.jpg"
import banner6 from "./assets/pageBanners/coimbatore.jpg"
import banner7 from "./assets/pageBanners/rameswaram.jpg"
import banner8 from "./assets/pageBanners/kodaikanalbanner.jpg"
import banner9 from "./assets/pageBanners/tiruvanandhapuram.jpg"
import banner10 from "./assets/pageBanners/kanyakumari.png"
import { madurai,thekkady,munnar,rameshwaram,kodaikanal,thiruvanandhapuram,kanyakumari,north_india,international ,honeymoon,south_india,dharshan, dharshan1} from './data';
import Packages from './components/Packages';
import Accomadtion from './components/Accomadtion';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsandCondition from './components/TermsandCondition';



function App() {
  return (
    <div>
      <BrowserRouter >
        <Routes>
          <Route path="/" element={< Home />}></Route>
          <Route path='/madurai' element={< Page  img={banner1} insight={madurai} head="MADURAI" />}></Route>
          <Route path='/thekkady' element={< Page img={banner2}  insight={thekkady} head="THEKKADY"/>}></Route>
          <Route path='/moonar' element={< Page img={banner3} insight={munnar} head="MUNNAR"/>}></Route>
          <Route path='/chennai' element={< Page img={banner4} insight={madurai} head="CHENNAI"/>}></Route>
          <Route path='/bangalore' element={< Page img={banner5} insight={madurai} head="BANGALORE"/>}></Route>
          <Route path='/coimbatore' element={< Page img={banner6} insight={madurai} head="COIMBATORE"/>}></Route>
          <Route path='/rameshwaram' element={< Page img={banner7}insight={rameshwaram} head="RAMESHWARAM"/>}></Route>
          <Route path='/kodaikanal' element={< Page img={banner8} insight={kodaikanal} head="KODAIKANAL"/>}></Route>
          <Route path='/thiruvanathapuram' element={< Page img={banner9} insight={thiruvanandhapuram} head="THIRUVANATHAPURAM"/>}></Route>
          <Route path='/kanayakumari' element={< Page img={banner10} insight={kanyakumari} head="KANAYAKUMARI"/>}></Route>
          <Route path='/darshan-tour-package' element={<Packages tour="DAHRASHAN TOUR PACKAGE" pack={dharshan} pack1={dharshan1}/>}></Route>
          <Route path='/domestic-tour-package' element={<Packages tour="DOMESTIC TOUR PACKAGE"/> }></Route>
          <Route path='/international-tour-package' element={<Packages tour="INTERNATIONAL TOUR PACKAGE" pack={international}/>}></Route>
          <Route path='/honeymoon-tour-package' element={<Packages tour="HONEYMOON TOUR PACKAGE" pack={honeymoon}/>}></Route>
          <Route path='/north-tour-package' element={<Packages tour="NORTH INDIA TOUR PACKAGE" pack={north_india}/>}></Route>
          <Route path='/south-tour-package' element={<Packages tour="SOUTH INDIA TOUR PACKAGE" pack={south_india}/>}></Route>
          <Route path='/karnataka-tour-package' element={<Packages tour="KARNATAKA TOUR PACKAGE"/>}></Route>
          <Route path='/accomodation' element={<Accomadtion/>}></Route>
          <Route path='/privacy-policy' element={<PrivacyPolicy />}></Route>
          <Route path='/terms-and-conditions' element={<TermsandCondition />} ></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
