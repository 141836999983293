 const telegramApi = '7575896685:AAHNx3M95sp5XDalPaj0K-ubZA_vfqBQnY0'; // Replace with your actual backend URL
const telegramChatId=1517805520;
const netbixTelegramApi = '6932943855:AAFR8HjIe96gyrBm-BiWyQzU0BvEt1mnmeo'; // Replace with your actual backend URL
const netbixTelegramChatId=1113684027;
export { telegramApi ,telegramChatId,netbixTelegramApi,netbixTelegramChatId};

// const telegramApi = '7181434842:AAHuc7W4Jo1SPBtLDMmObXNRi_AigIaU8Ec'; // Replace with your actual backend URL
// const telegramChatId=6447523004;

// 7575896685:AAHNx3M95sp5XDalPaj0K-ubZA_vfqBQnY0
// 1517805520
// aajavo