import React, { Fragment, useEffect, useRef,useState } from 'react'
import About from './About';
import Banner from './Banner';
import Footer from './Footer';
import Header from './Header';
import Look from './Look';
import PhoneComponent from './PhoneComponent';
import Price from './Price';
import Ride from './Ride';
import ScrollUp from './ScrollUp';
import WhatsappComponent from './WhatsappComponent';
import TermsandCondition from './TermsandCondition';
import PrivacyPolicy from './PrivacyPolicy';
import { animateScroll as scroll } from 'react-scroll';
import TouristPlaces from './TouristPlaces';
import AirportTransfer from './AirportTransfer';
import Reviews from './Reviews';


const Home = () => {
    const [changeLink, setChangeLink] = useState('home');
    // alert(changeLink);
    useEffect(() => {
        if(changeLink){
            scroll.scrollToTop({ smooth: false });
        }
    }, [changeLink])

    const scrollRef=useRef(null)


    useEffect(() => {
        // After the component mounts, scroll to the "about" section on the Home page
        if (scrollRef.current) {
          scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        }      
      }, []);
    
    return (
        <Fragment>
            <Header setChangeLink={setChangeLink}/>
            {changeLink=="home" ? (
                <Fragment>
                    <Banner />
                    <About scrollRef={scrollRef}/>
                    <Price />
                    <AirportTransfer/>
                    <TouristPlaces/>
                 {/* <TopRoutes />*/}
                    <Look />
                    <Reviews/>
                    <WhatsappComponent />
                    <PhoneComponent />
                    <ScrollUp />
                </Fragment>
            ) : ""}
            {changeLink == "terms" ? (
                <Fragment>
                    <TermsandCondition />
                    <WhatsappComponent />
                    <PhoneComponent />
                    <ScrollUp />
                </Fragment>
            ) : ""}
            {changeLink == "policy" ? (
                <Fragment>
                    <PrivacyPolicy />
                    <WhatsappComponent />
                    <PhoneComponent />
                    <ScrollUp />
                </Fragment>
            ) : ""}
            <Footer setChangeLink={setChangeLink} />
        </Fragment>
    )
}

export default Home