import React from 'react'
import Header from './Header'
import Footer from './Footer'
import PageBanner from './PageBanner'
import ExculsivePackages from './ExculsivePackages'
import Insight from './Insight'
import { useEffect } from 'react'


const Page = ({img,insight,head}) => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, [])
  return (
     <div>
      <Header/>
      <PageBanner img={img}/>
      <Insight insight={insight} head={head}/>
      <ExculsivePackages/>
      <Footer/>
     </div>
  )
}

export default Page