import Header from './Header'
import Footer from './Footer'
import React from 'react'
import AccomadtionDetails from './AccomadtionDetails'

const Accomadtion = () => {
  return (<>
    <Header/>
    <AccomadtionDetails/>
    <Footer/>
 </> )
}

export default Accomadtion